import React from 'react';
import RequestView from '../components/requestView';
import { compose, getCookieFlag, setCookieFlag } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import { IDP_REQUEST } from '../graphql/userIdpList.gql';
import { idp_status } from '../idp.config';
import { getAiTipCacheTimer, getIntFromString, globalPermissionValidator } from 'modules/look';
import { idp_permission } from 'Permissions/idp.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { Spin } from 'antd'
import { ai_trigger_content } from 'modules/ai-tips/ai-trigger-content';
import moment from 'moment';
const RequestContainer = (props) => {
  const { me, client, userPermission, SetAITrigger, ai_trigger } = props
  const [idp_page_info, SetIdpPageInfo] = React.useState()
  const [request_list, setRequestList] = React.useState([])
  const [request_list_loading, setRequestLoading] = React.useState(true)
  const [permission, setPermission] = React.useState()
  const filter_ref = React.useRef({})
  const reuest_list_ref = React.useRef([])
  let available_status = Object.values(idp_status)?.map(i => i?.for_mutation)?.filter(i => i != idp_status?.Draft?.for_mutation)

  //Permission Check
  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(idp_permission, userPermission)
      setPermission(permission)
    }
  }, [userPermission])
  React.useEffect(() => {
    reuest_list_ref.current = request_list
  }, [request_list])

  React.useEffect(() => {
    if (me?.employee && permission?.required_permission) {
      idpRequest({ first: 10, approver_Id: me?.id, status_In: available_status, trigger_ai_tip: true })
    }
  }, [me, permission])

  const idpRequest = async (filter) => {
    filter_ref.current = { ...filter, cursor: null } || {}
    setRequestLoading(true)
    const { data } = await client.query({
      query: IDP_REQUEST,
      variables: { ...filter },
      fetchPolicy: 'network-only'
    });
    if (data?.allIdp) {
      SetIdpPageInfo({
        hasNextPage: data?.allIdp?.pageInfo?.hasNextPage,
        cursor: data?.allIdp?.pageInfo?.endCursor,
      })

      let idpLists = data?.allIdp?.edges?.map(({ node }) => node)
      if (filter?.cursor) {
        idpLists = reuest_list_ref.current.concat(idpLists)
      }
      setRequestList(idpLists)
      if (!ai_trigger && filter?.trigger_ai_tip) {
        let inprogress_idp = [idp_status?.Approved?.for_query, idp_status?.ToDo?.for_query, idp_status?.InProgress?.for_query]
        let pending_approval = [idp_status?.Pedning_Approval.for_query]
        let cached_trigger_manager_idp_pending_approval_due_passed = await getCookieFlag(`manager_idp_pending_approval_due_passed_${getIntFromString(me?.id)}`)
        let cached_trigger_manager_idp_pending_approval_due_upcoming = await getCookieFlag(`manager_idp_pending_approval_due_upcoming_${getIntFromString(me?.id)}`)
        let cached_trigger_manager_idp_inprogress_due_passed = await getCookieFlag(`manager_idp_inprogress_due_passed_${getIntFromString(me?.id)}`)

        if (!cached_trigger_manager_idp_pending_approval_due_passed && idpLists?.some(i => pending_approval?.includes(i?.status) && moment(i?.duedate).isBefore(moment()))) {
          let idp_trigger_list = idpLists?.filter(i => pending_approval?.includes(i?.status) && moment(i?.duedate).isBefore(moment()))
          SetAITrigger({
            trigger: ai_trigger_content.manager_idp_pending_approval_due_passed,
            optype: "LIST",
            dynamic_data:idp_trigger_list?.map(i=>i?.goal)
          })
          setCookieFlag(`manager_idp_pending_approval_due_passed_${getIntFromString(me?.id)}`, JSON.stringify({ optype: "LIST", type: "manager_idp_pending_approval_due_passed" }), { expires: getAiTipCacheTimer() })

        }
        else if (!cached_trigger_manager_idp_pending_approval_due_upcoming && idpLists?.some(i => pending_approval?.includes(i?.status) && moment(i?.duedate).isAfter(moment()))) {
          let idp_trigger_list = idpLists?.filter(i => pending_approval?.includes(i?.status) && moment(i?.duedate).isAfter(moment()))
          SetAITrigger({
            trigger: ai_trigger_content.manager_idp_pending_approval_due_upcoming,
            optype: "LIST",
            dynamic_data:idp_trigger_list?.map(i=>i?.goal)
          })
          setCookieFlag(`manager_idp_pending_approval_due_upcoming_${getIntFromString(me?.id)}`, JSON.stringify({ optype: "LIST", type: "manager_idp_pending_approval_due_upcoming" }), { expires: getAiTipCacheTimer() })
        }
        else if (!cached_trigger_manager_idp_inprogress_due_passed && idpLists?.some(i => inprogress_idp?.includes(i?.status) && moment(i?.duedate).isAfter(moment()))) {
          let idp_trigger_list = idpLists?.filter(i => inprogress_idp?.includes(i?.status) && moment(i?.duedate).isAfter(moment()))
          SetAITrigger({
            trigger: ai_trigger_content.manager_idp_inprogress_due_passed,
            optype: "LIST",
            dynamic_data:idp_trigger_list?.map(i=>i?.goal)
          })
          setCookieFlag(`manager_idp_inprogress_due_passed_${getIntFromString(me?.id)}`, JSON.stringify({ optype: "LIST", type: "manager_idp_inprogress_due_passed" }), { expires: getAiTipCacheTimer() })

        }

      }

    }
    setRequestLoading(false)
  }

  return (
    <>
      {
        (permission && permission?.required_permission) && (
          <RequestView {...props}
            request_list={request_list}
            request_list_loading={request_list_loading}
            idp_request_page_info={idp_page_info}
            getRequestIdpList={(filter) => idpRequest({ ...filter_ref.current, ...filter })} />)}
      {(permission && !permission?.required_permission) && (<NoPermissionView />)}
      {
        !permission && (
          <div className="role-no-permission-container">
            <Spin spinning={true} size="large"></Spin>
          </div>
        )
      }
    </>
  )

}

export default compose(withApollo)(RequestContainer);