export const zone_detail_content = {
    green: `Green zone - Possible weakness, aspiration, clear aspiration, possible strength. The green zone is the starting point for a task / work area.

Possible weakness: An employee may be no-low in knowledge, skills and experience and they may also be no-low in interest. It may be indicative of a weakness. But we may not fully know yet. However, we need to tread carefully. It's important to unpack what's leading to the lack of interest: a perception that the activity is monotonous/boring/low value or difficult/out of league leading to fear of failure and impact on career.

Aspiration/clear aspiration: No-low on knowledge, skills and experience but mid-High on interest can indicate that this person perceives the task at hand attractive and even if we may not be sure until they test it out, their current perception is this is something they will enjoy and be good at.

Possible strength: It's important to clarify what's leading to the positive perception of the activity and explore behavioral proof that this indeed may be a work area the employee may enjoy and grow in. Have they gained some knowledge in this area on their own already? Are there any similar activities that they have done and enjoyed? Have they worked with/reached out to anybody that does the activity and understand what it involves? Is there a career goal they have that is supported by growing in this work area? If there are positive answers to some of these, it's a good indication that we may even be in a possible strength area and it may turn out to be a good investment for the employee, as well as the team/org.`,
    orange: `The orange zone is the learning curve. This zone involves the intern, the learner and the struggling learner.

Intern: The employee has some knowledge and skill in this area by now and while they still lack experience, the aspiration is supported by high interest. So they are raring to take on the learning journey and prove themselves. 

Learner: As the jump in and start applying their knowledge and skills to the real world, the high interest is still sustained. That is a good indication of a strength being discovered and the employee may be engrossed and enjoying the initial easy learning ramp of a new task. It soon may change.

Struggling learner: They may have acquired some experience but as the application starts getting tougher, their interest may start showing ups and downs given the amount of heavy lifting involved. It's important to encourage and enable the employee to stay through this phase and explore the task further to ensure this is not just because of the learning challenge rather than because of lack of interest. This phase is a tricky one because it can go either way and therefore, you are placing a bet. The employee may spend a considerable amount of time learning the task, not getting very far in it and feeling like having wasted their time and based on whether the manager pushed it or not, led on / cheated. Being careful in the decisions in this phase, as well as the support provided to the employee is crucial. 

Note
A person can go through all the phases, go through them really quickly not to notice some, or may skip a phase. These are not non-negotiable. Sustained high interest may make somebody never experience the struggle of a struggling learner or an employee may never genuinely experience the struggle.`,

    purple: `The purple zone is of sustained learning.Strengthening/Confirmed strength: This zone confirms that a task/work area is a strength. While now the employee has improved in knowledge, skills and experience in a noticeable way, their interest may still be fluctuating as none of those areas are yet at high and the learning curve may still be creating an lack of certainty around the confirmation of a strength. But as the employee persists and amps up the knowledge to high and while skills and experience may still being mid, interest stabilizes as high, this confirms the hypothesis of a strength. Investing further in this learning journey is going to be useful.`,
    red:`The red zone is the zone of growing a weakness.It creates a draining, de-energizing experience.

Weakness/strong weakness: The purple zone can eventually come to a place where the fluctuating interest may not stabilize as high. It may actually drop. Knowledge and skills can be mid but no-low experiecence and no-low interest is a good indicator of a weakness. If the experience also amps up to mid but the interest stabilizes as no-low, then it's a confirmed weakness. Going further down this path is not going to be fruitful unless there's no choice. 

Misplaced/Forced investment: This is the stage where the employee has persisted and has fine tuned their skills through experience and are able to deliver a task at high quality standards. However, in spite of the learning journey, they still have no-low interest in the task. This turns out to be the necessary evil and a misplaced investment. If the manager pushed the employee to take on this journey, this is going to feel like a forced investment and of low value for the employee as growth / career development.`,
blue:`The blue zone is the zone of performance
In this phase, the employee continues to uplevel knowledge, skills and experience and is able to deliver outcomes expected from the task at a satisfcatory level or better. 

Productive: High knowledge and skills and mid experience is a combination where the employee is getting the job done. The have the know-how and they are fine tuning it with skill development. They are at a place where they are able to get the job done. They may yet have to demonstrate a nuanced, advanced understanding of the task and how to apply the knowledge and skills to real life scenarios in that work area. 

Master: Through fine-tuning the knowledge and skills, the employee now is able to get the job done at a high quality level and is considered a master at the task. You can rely on them to be able to do the job independently, make decisions related to the task and have the discretion to know when to come to you to bounce things off. `
}