export const ai_trigger_content = {
    "idp_inprogress": [
        {
            title: "Completing Individual Development Plan",
            ai_context: `Trying to complete my individual development plans such as {{dynamic_1}}. I need to know few of the steps that i can take to complete these idp before the due date`,
            dynamic_data: 1
        }
    ],
    "idp_pending_approval": [
        {
            title: "Viewing Pending Individual Development Plan",
            ai_context: `My manager has not approved few of my Individual Development Plan such as {{dynamic_1}}. Can you help me understand the next steps I should take?`,
            dynamic_data: 1
        } 
    ],
    "idp_revise": [
        {
            title: "Viewing Revised Individual Development Plan",
            ai_context: `Can you help me revise my Individual Development Plan such as {{dynamic_1}}.`,
            dynamic_data: 1
        } 
    ], 
    "okr_create_keyresult": [
        {
            title: "Creating keyresult under Objective - list 3 different keyresult ",
            ai_context:"objective_name: {{dynamic_1}} ",
            dynamic_data: 1
        } 
    ],
    "okr_create_milestone": [
        {
            title: "Creating Milestone under KeyResult - list 3 different milestone",
            ai_context:"keyresult_name: {{dynamic_1}}",
            dynamic_data: 1
        } 
    ],
    "okr_due_date_passed": [
        {
            title: "Viewing objective with passed due date",
            ai_context: "My objective's due date has passed. list of okr are {{dynamic_1}}",
            
            dynamic_data:1
        }
         
    ],
    "okr_due_date_upcoming": [
        {
            title: "Viewing objective with upcoming due date",
            ai_context: "My objective due date is coming up. Suggest some tips to complete them before the due date. Here is the list of okr {{dynamic_1}}",
            
            dynamic_data:1
        } 
    ],
    "okr_summary": [
        {
            title: "Viewing OKR detail - Considering the progress and due date of okr",
            ai_context: "", 
        }
    ],
    "motivational_feedback": [
        {
            title: "Motivational Feedback",
            ai_context: `I am about to write motivational feedback to my peer, and my earlier feedback to my peer is given in the above context Allfeedback. considering the earlier data, I am looking for improving my feedback skills.`,
            from_cache: true 
        }
    ],
    "developmental_feedback": [
        {
            title: "Developmental Feedback",
            ai_context:  `I am about to write developmental feedback to my peer, and my earlier feedback to my peer is given in the above context Allfeedback. considering the earlier data, I am looking for improving my feedback skills.`,
            from_cache: true 
        }
    ],
    "developmental_feedback_summary": [
        {
            title: "Actionable Steps for Developmental Feedback - From the developmental feedback received. Could you suggest actionable next steps I should take",
            ai_context: ` `,
             
        },        
    ],
    "feedbak_preview_tip_development":[
        {
            title:"Previewing the feedback  - Can you provide suggestions or improvements for my feedback content?",
            ai_context: `I am drafting a feedback to share it with my peer. I am using CLAPS model where C is 'Create Safety', L 'Layout Context', A is 'Articulate behavioral evidence and then impact', P is 'Probe for possible alternatives', S is 'Support for next steps and commitments' \n Draft Feedback: {{dynamic_1}}`,
            dynamic_data: 1
        }
    ],
    "feedbak_preview_tip_motivation":[
        {
            title:"Previewing the feedback  - Can you provide suggestions or improvements for my feedback content?",
            ai_context: `I am drafting a feedback to share it with my peer. I am using CLAS model where C is 'Create Safety', L 'Layout Context', A is 'Articulate behavioral evidence and then impact', S is 'Support for next steps and commitments' \n Draft Feedback: {{dynamic_1}}`,
            dynamic_data: 1
        }
    ],
    "give_kudos": [
        {
            title: "Award Kudos For Feedback",
            ai_context: `Can you help me write feedback that includes kudos for a job well done?`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            ai_context: `Assist me in crafting a message that gives kudos to recognize great work.`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            ai_context: `Could you guide me in giving kudos in feedback to highlight specific achievements?`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            ai_context: `Help me create kudos-focused feedback that celebrates accomplishments`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            ai_context: `Can you provide tips for giving kudos in feedback that shows appreciation and recognition?`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            ai_context: `How can I phrase feedback to effectively give kudos for exceptional performance?`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            ai_context: `Assist me in giving kudos within my feedback to inspire and motivate my team.`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            ai_context: `What’s an effective way to incorporate kudos in feedback to show appreciation?`,
            from_cache: true
        },
        {
            title: "Award Kudos For Feedback",
            ai_context: `I'd like guidance on giving kudos in feedback to recognize and encourage continued excellence.`,
            from_cache: true
        }
    ],

    "readiness_for_others": [
        {
            title: "Create Readiness For Others",
            ai_context: `Can you help me develop a readiness plan for my team members?`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            ai_context: `Assist me in creating a readiness assessment for my team to ensure they’re prepared.`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            ai_context: `Could you guide me in designing strategies to improve my team’s readiness?`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            ai_context: `What should I include in a readiness plan to ensure my team is fully prepared for upcoming tasks?`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            ai_context: `Help me create a readiness framework that supports my team’s development and preparedness.`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            ai_context: `Can you provide tips for evaluating and building readiness among my team members?`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            ai_context: `How can I create a readiness strategy to support my team in meeting project goals?`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            ai_context: `Assist me in identifying key areas of readiness to focus on for my team’s growth.`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            ai_context: `What are effective ways to assess and increase readiness across my team?`,
            from_cache: true
        },
        {
            title: "Create Readiness For Others",
            ai_context: `I'd like guidance on developing a readiness plan that prepares my team for new challenges.`,
            from_cache: true
        }
    ],
    "readiness_for_my_self": [
        {
            title: "Create Readiness For My Self",
            ai_context: `Can you help me develop a readiness plan to prepare myself for upcoming challenges?`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            ai_context: `Assist me in assessing and building my own readiness for new responsibilities.`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            ai_context: `Could you guide me in creating a personal readiness plan to strengthen my skills?`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            ai_context: `What steps should I take to ensure I'm fully prepared for future tasks and goals?`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            ai_context: `Help me design a readiness strategy that supports my personal development.`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            ai_context: `Can you provide tips to assess and improve my readiness for upcoming projects?`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            ai_context: `How can I create a plan to boost my readiness for new opportunities?`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            ai_context: `Assist me in identifying key areas of readiness to focus on for my personal growth.`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            ai_context: `What are some strategies for enhancing my readiness for professional challenges?`,
            from_cache: true
        },
        {
            title: "Create Readiness For My Self",
            ai_context: `I'd like guidance on building a readiness plan that prepares me for future success.`,
            from_cache: true
        }
    ],
    "readiness_add_new_skill": [
        {
            title: "Add New Skill",
            ai_context: `Can you guide me in developing a plan to learn a new skill effectively?`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            ai_context: `Assist me in creating a step-by-step approach to building a new skill.`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            ai_context: `Could you help me outline a strategy to acquire a new skill quickly?`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            ai_context: `What steps should I take to develop a new skill from scratch?`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            ai_context: `Help me create a skill-building plan to master something new.`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            ai_context: `Can you provide a structured approach to learning and practicing a new skill?`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            ai_context: `How can I set realistic goals to track my progress in developing a new skill?`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            ai_context: `Assist me in identifying resources and techniques to build a new skill.`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            ai_context: `What are effective ways to learn and practice a new skill?`,
            from_cache: true
        },
        {
            title: "Add New Skill",
            ai_context: `I’d like help with creating a plan to develop a new skill—can you assist?`,
            from_cache: true
        }
    ],
    "readiness_survey_assist": [
        {
            title: "Readiness Survey Assist",
            ai_context: `Can you guide me through completing the readiness survey effectively?`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            ai_context: `Assist me in understanding and responding accurately to the readiness survey questions.`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            ai_context: `Could you help me prepare for taking the readiness survey?`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            ai_context: `What tips do you have for answering the readiness survey with confidence?`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            ai_context: `Help me approach the readiness survey to ensure thorough and honest responses.`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            ai_context: `Can you provide guidance on how to best reflect my readiness in the survey?`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            ai_context: `How should I assess myself for an accurate readiness survey response?`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            ai_context: `Assist me in interpreting and completing each question in the readiness survey.`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            ai_context: `What’s the best way to prepare myself for taking the readiness survey?`,
            from_cache: true
        },
        {
            title: "Readiness Survey Assist",
            ai_context: `I’d like help in filling out the readiness survey thoughtfully—can you assist?`,
            from_cache: true
        }
    ],
    "readiness_zone_based": [
        {
            title: "Readiness Self Assessment Zone Summary",
            ai_context: `Can you give me a summary of my readiness zone for {{dynamic_1}}?`,
            
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            ai_context: `Assist me in understanding the summary of my readiness level in {{dynamic_1}}.`,
            
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            ai_context: `Could you provide an overview of my readiness zone for {{dynamic_1}}?`,
            
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            ai_context: `What’s a brief summary of my current readiness in {{dynamic_1}}?`,
            
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            ai_context: `Help me understand my readiness summary for {{dynamic_1}}.`,
            
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            ai_context: `Can you give me a quick recap of my readiness zone status for {{dynamic_1}}?`,
            
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            ai_context: `What are the key points of my readiness zone in {{dynamic_1}}?`,
            
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            ai_context: `Provide a summary of how prepared I am in the {{dynamic_1}} readiness zone.`,
            
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            ai_context: `Assist me in interpreting the summary of my readiness for {{dynamic_1}}.`,
            
            dynamic_data: 1
        },
        {
            title: "Readiness Self Assessment Zone Summary",
            ai_context: `I’d like an overview of my readiness zone for {{dynamic_1}}—can you help?`,
            
            dynamic_data: 1
        }
    ],
    "readiness_assessment_skill_summary": [
        {
            title: "Viewing Readiness Assessment - consider the current_zone by analyzing the zone_detail,skill and assessment_detail",
            ai_context: "current_zone: {{dynamic_1}}, skill:{{dynamic_2}}, assessment_detail: {{dynamic_3}}, zone_detail:{{dynamic_4}},{{dynamic_5}}",
            
            dynamic_data: 4
        },
    ]
    ,
    "one_on_one_meeting_schedule": [
        {
            title: "One on one meeting schedule",
            ai_context: "Can you help me schedule a one-on-one meeting by suggesting a convenient date and time?",
            
        },
        {
            title: "One on one meeting schedule",
            ai_context: "Assist me in setting up a one-on-one meeting, including sending a calendar invite and reminder.",
            
        },
        {
            title: "One on one meeting schedule",
            ai_context: "Could you guide me in picking a suitable time and sending out an invite for a one-on-one meeting?",
            
        },
        {
            title: "One on one meeting schedule",
            ai_context: "What’s the best way to choose a date, send an invite, and set a reminder for a one-on-one meeting?",
            
        },
        {
            title: "One on one meeting schedule",
            ai_context: "Help me organize a one-on-one meeting by finding a good time, sending an invite, and setting reminders.",
            
        },
        {
            title: "One on one meeting schedule",
            ai_context: "Can you assist me in selecting a convenient time and scheduling a one-on-one meeting with a calendar invite?",
            
        },
        {
            title: "One on one meeting schedule",
            ai_context: "How can I schedule a one-on-one meeting efficiently, including an invite and reminders?",
            
        },
        {
            title: "One on one meeting schedule",
            ai_context: "Assist me with setting up a one-on-one meeting, including picking a time, sending an invite, and reminders.",
            
        },
        {
            title: "One on one meeting schedule",
            ai_context: "What steps should I take to arrange a one-on-one meeting with a calendar invite and reminders?",
            
        },
        {
            title: "One on one meeting schedule",
            ai_context: "I'd like help scheduling a one-on-one meeting—could you assist with the date, invite, and reminders?",
            
        }
    ],
    "one_on_one_series_meeting_schedule": [
        {
            title: "One on one series meeting",
            ai_context: "Can you help me schedule a series of one-on-one meetings, ensuring no conflicts and timely reminders?",
            
        },
        {
            title: "One on one series meeting",
            ai_context: "Assist me in organizing multiple one-on-one meetings, including dates, times, and calendar invites for participants.",
            
        },
        {
            title: "One on one series meeting",
            ai_context: "Could you guide me in scheduling a series of one-on-one meetings with conflict-free timing and reminders?",
            
        },
        {
            title: "One on one series meeting",
            ai_context: "Help me set up recurring one-on-one meetings, coordinating dates, invites, and reminders for each participant.",
            
        },
        {
            title: "One on one series meeting",
            ai_context: "What’s the best approach for scheduling multiple one-on-one meetings without conflicts and with proper reminders?",
            
        },
        {
            title: "One on one series meeting",
            ai_context: "Can you support me in planning a series of one-on-ones, ensuring all participants receive invites and reminders?",
            
        },
        {
            title: "One on one series meeting",
            ai_context: "Assist me in arranging a sequence of one-on-one meetings, with dates, times, invites, and conflict management.",
            
        },
        {
            title: "One on one series meeting",
            ai_context: "How can I organize multiple one-on-ones smoothly, with calendar invites and reminders for each attendee?",
            
        },
        {
            title: "One on one series meeting",
            ai_context: "Help me schedule a series of one-on-one meetings with no conflicts and set reminders for all participants.",
            
        },
        {
            title: "One on one series meeting",
            ai_context: "I’d like assistance in scheduling recurring one-on-one meetings, including invites, reminders, and conflict checks.",
            
        }
    ],
    "one_on_one_create_agenda": [
        {
            title: "Creating meeting agenda",
            ai_context: "Write a brief and engaging description for a meeting agenda titled '{{dynamic_1}}'.The description should be professional and concise, outlining key topics and goals of the meeting to provide attendees with a clear understanding of what to expect.",
            
            dynamic_data: 1
        },
    ],
    "one_on_one_create_action_item": [
        {
            title: "Meeting Action Item",
            ai_context: "Write a concise and engaging description for a meeting action item titled '{{dynamic_1}}'. The description should outline the key topics or objectives of the meeting, providing attendees with a clear understanding of the action item's purpose. Use a professional and informative tone.",
            
            dynamic_data: 1
        },
    ],
    "meeting_detail_action_item": [
        {
            title: "Follow up on meeting action item",
            ai_context: "Based on the following meeting action items: {{dynamic_1}}, provide guidance on the most effective next steps to take. The suggestions should be actionable, clear, and tailored to ensure progress and successful outcomes.",
            
            dynamic_data: 1
        }
    ],
  
    "survey_3_completed": [
        {
            title: "Top 5 Values",
            ai_context: "Give me a summary of my top values and guidance on using them to advance my professional career. My values are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            ai_context: "Help me understand how to apply my top values to improve my career path. My values are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            ai_context: "Provide a summary of my core values and suggest ways to integrate them into my professional development. Values: {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            ai_context: "Give a brief summary of my key values and how I can leverage them to enhance my career. Top values are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            ai_context: "Summarize my values and offer advice on using them to advance professionally. Values include {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            ai_context: "Help me understand my core values and suggest ways to use them for career growth. My values: {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            ai_context: "Give a summary of my main values and strategies to use them effectively in my career. Values are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            ai_context: "Summarize my primary values and help me leverage them to build a successful career. My values are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            ai_context: "Provide a summary of my values and ideas on how they can support my career improvement. Key values are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Value"
        },
        {
            title: "Top 5 Values",
            ai_context: "Help me understand how to apply my top values to enhance my career path. My core values are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Value"
        }
    ],
    "survey_3_pending": [
        {
            title: "Complete Top 5 Values Survey",
            ai_context: "Suggest that I complete the Top 5 Values survey to generate an impact narrative and provide details about the survey.",
            
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            ai_context: "Recommend I take the Top 5 Values survey for an impact narrative and explain what the survey involves.",
            
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            ai_context: "Guide me to complete the Top 5 Values survey to create an impact narrative and share information on the survey itself.",
            
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            ai_context: "Encourage me to complete the Top 5 Values survey and provide details on how it supports an impact narrative.",
            
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            ai_context: "Suggest I take the Top 5 Values survey to help develop an impact narrative, including information about the survey’s purpose.",
            
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            ai_context: "Recommend completing the Top 5 Values survey for impact narrative purposes and give a brief overview of the survey.",
            
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            ai_context: "Prompt me to complete the Top 5 Values survey to build an impact narrative, and provide some insight into the survey content.",
            
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            ai_context: "Encourage me to take the Top 5 Values survey for impact narrative development and offer information about the survey itself.",
            
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            ai_context: "Guide me towards completing the Top 5 Values survey and explain how it contributes to creating an impact narrative.",
            
            module: "Value"
        },
        {
            title: "Complete Top 5 Values Survey",
            ai_context: "Suggest taking the Top 5 Values survey to generate an impact narrative and describe what the survey entails.",
            
            module: "Value"
        }
    ],
    "survey_7_completed": [
        {
            title: "Top 5 Strength",
            ai_context: "Give me a summary of my top strengths and guidance on how to use them to advance my professional career. My strengths are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            ai_context: "Help me understand how to apply my top strengths to improve my career path. My strengths are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            ai_context: "Provide a summary of my core strengths and suggest ways to integrate them into my professional development. Strengths: {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            ai_context: "Give a brief summary of my key strengths and how I can leverage them to enhance my career. Top strengths are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            ai_context: "Summarize my strengths and offer advice on using them to advance professionally. Strengths include {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            ai_context: "Help me understand my core strengths and suggest ways to use them for career growth. My strengths: {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            ai_context: "Give a summary of my main strengths and strategies to use them effectively in my career. Strengths are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            ai_context: "Summarize my primary strengths and help me leverage them to build a successful career. My strengths are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            ai_context: "Provide a summary of my strengths and ideas on how they can support my career improvement. Key strengths are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Strength"
        },
        {
            title: "Top 5 Strength",
            ai_context: "Help me understand how to apply my top strengths to enhance my career path. My core strengths are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Strength"
        }
    ],
    "survey_7_pending": [
        {
            title: "Complete Top 5 Strength Survey",
            ai_context: "Suggest that I complete the Top 5 Strength survey to generate an impact narrative and provide details about the survey.",
            
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            ai_context: "Recommend I take the Top 5 Strength survey to develop an impact narrative and explain what the survey involves.",
            
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            ai_context: "Guide me to complete the Top 5 Strength survey to create an impact narrative and share information on the survey itself.",
            
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            ai_context: "Encourage me to complete the Top 5 Strength survey to support an impact narrative and provide details on how the survey helps.",
            
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            ai_context: "Suggest I take the Top 5 Strength survey for impact narrative creation, including information on the survey’s purpose.",
            
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            ai_context: "Recommend completing the Top 5 Strength survey for developing an impact narrative and give a brief overview of the survey.",
            
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            ai_context: "Prompt me to complete the Top 5 Strength survey to build an impact narrative and provide some insight into the survey content.",
            
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            ai_context: "Encourage me to take the Top 5 Strength survey for impact narrative development and offer information about the survey itself.",
            
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            ai_context: "Guide me towards completing the Top 5 Strength survey and explain how it contributes to creating an impact narrative.",
            
            module: "Strength"
        },
        {
            title: "Complete Top 5 Strength Survey",
            ai_context: "Suggest taking the Top 5 Strength survey to generate an impact narrative and describe what the survey entails.",
            
            module: "Strength"
        }
    ],
    "survey_8_completed": [
        {
            title: "My Personality",
            ai_context: "Give me a summary of my personality traits based on the following characteristics: {{dynamic_1}}. Explain how these traits influence my behavior and interactions.",
            
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            ai_context: "Summarize my personality by analyzing these traits: {{dynamic_1}}. Provide insights on how they shape my personal and professional relationships.",
            
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            ai_context: "Please provide a summary of my personality using the traits listed: {{dynamic_1}}. Discuss how I can use these strengths in my career.",
            
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            ai_context: "Give me an overview of my personality traits based on {{dynamic_1}}. Include suggestions on how to maximize these traits in my daily life.",
            
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            ai_context: "Summarize my personality characteristics as represented by {{dynamic_1}}. Share tips on how I can enhance my interactions using these traits.",
            
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            ai_context: "Provide a comprehensive summary of my personality traits, focusing on {{dynamic_1}}. Explain how these traits can impact my decision-making.",
            
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            ai_context: "Please summarize my personality, highlighting the traits {{dynamic_1}}. Offer advice on how to navigate challenges related to these traits.",
            
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            ai_context: "Summarize my personality using these characteristics: {{dynamic_1}}. Discuss potential career paths that align with my personality.",
            
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            ai_context: "Give me a detailed summary of my personality traits based on {{dynamic_1}}. Include how I can develop these traits further.",
            
            dynamic_data: 1,
            module: "Personality"
        },
        {
            title: "My Personality",
            ai_context: "Provide an overview of my personality with respect to these traits: {{dynamic_1}}. Suggest ways to enhance my strengths and address weaknesses.",
            
            dynamic_data: 1,
            module: "Personality"
        }
    ],
    "survey_8_pending": [
        {
            title: "Complete Personality Survey",
            ai_context: "Encourage me to complete the personality survey to help generate my impact narrative. Please explain how understanding my personality can benefit my personal growth.",
            
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            ai_context: "Suggest that I take the personality survey to create an impact narrative. Highlight how this can provide insights into my strengths and areas for improvement.",
            
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            ai_context: "Prompt me to complete the personality survey for generating an impact narrative. Describe how this survey will enhance my self-awareness and decision-making.",
            
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            ai_context: "Advise me to finish the personality survey to aid in generating my impact narrative. Emphasize the importance of understanding my personality traits.",
            
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            ai_context: "Remind me to complete the personality survey as it is essential for generating an impact narrative. Discuss how it will assist in clarifying my career goals.",
            
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            ai_context: "Encourage me to take the personality survey to support my impact narrative development. Share how the insights gained can inform my future choices.",
            
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            ai_context: "Motivate me to complete the personality survey to create an impactful narrative. Explain how the results can guide my personal and professional development.",
            
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            ai_context: "Suggest I complete the personality survey to generate my impact narrative. Provide reasons why this is a crucial step in my self-improvement journey.",
            
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            ai_context: "Urge me to finish the personality survey as it will help me generate my impact narrative. Highlight how it can reveal key insights into my interactions with others.",
            
            module: "Personality"
        },
        {
            title: "Complete Personality Survey",
            ai_context: "Prompt me to complete the personality survey to generate an impact narrative. Discuss how this will help me better understand my motivations and values.",
            
            module: "Personality"
        }
    ],
    "survey_9_completed": [
        {
            title: "Top 5 Knowledge & Skills",
            ai_context: "Provide a summary of my knowledge and skills, focusing on how I can leverage them for self-improvement and to assist my colleagues. My knowledge and skills are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            ai_context: "Give me an overview of my knowledge and skills and suggest ways to apply them effectively to enhance my career and support my colleagues. My knowledge and skills are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            ai_context: "Summarize my knowledge and skills and provide guidance on how I can utilize them to foster my personal development and assist my team. My knowledge and skills are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            ai_context: "Please summarize my knowledge and skills and outline how I can use them to grow professionally and aid my colleagues in their tasks. My knowledge and skills are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            ai_context: "Give me a comprehensive summary of my knowledge and skills and suggest actionable ways to improve myself and support my colleagues. My knowledge and skills are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            ai_context: "Provide a detailed summary of my knowledge and skills and recommend strategies for applying them to enhance my performance and assist my coworkers. My knowledge and skills are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            ai_context: "Summarize my knowledge and skills and advise me on how to use them effectively to improve my capabilities and help my team members. My knowledge and skills are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            ai_context: "Give me a summary of my knowledge and skills and discuss how I can leverage them for self-improvement and team collaboration. My knowledge and skills are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            ai_context: "Provide a summary of my knowledge and skills, and suggest practical ways to apply them to enhance my career trajectory and assist my colleagues. My knowledge and skills are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Knowledge_Skills"
        },
        {
            title: "Top 5 Knowledge & Skills",
            ai_context: "Summarize my knowledge and skills and offer advice on how I can leverage them for my own development and to contribute to my colleagues' success. My knowledge and skills are {{dynamic_1}}.",
            
            dynamic_data: 1,
            module: "Knowledge_Skills"
        }
    ],
    "survey_9_pending": [
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            ai_context: "Please encourage me to complete the Top 5 Knowledge & Skills survey, as it will help in generating my impact narrative. Explain how this survey can provide valuable insights into my strengths.",
            
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            ai_context: "Suggest that I take the Top 5 Knowledge & Skills survey to create an impactful narrative. Highlight the benefits of understanding my top skills and knowledge areas.",
            
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            ai_context: "Prompt me to complete the Top 5 Knowledge & Skills survey to assist in generating my impact narrative. Discuss how it can enhance my self-awareness and career growth.",
            
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            ai_context: "Advise me to finish the Top 5 Knowledge & Skills survey as it is crucial for generating my impact narrative. Emphasize the significance of identifying my key skills.",
            
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            ai_context: "Remind me to complete the Top 5 Knowledge & Skills survey to help in creating my impact narrative. Explain how it will clarify my strengths and weaknesses.",
            
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            ai_context: "Encourage me to take the Top 5 Knowledge & Skills survey to support my impact narrative development. Share how the insights will guide my professional journey.",
            
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            ai_context: "Motivate me to complete the Top 5 Knowledge & Skills survey to generate an impactful narrative. Explain how this can inform my career path and development goals.",
            
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            ai_context: "Suggest I complete the Top 5 Knowledge & Skills survey to enhance my impact narrative. Provide reasons why this survey is essential for my personal growth.",
            
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            ai_context: "Urge me to finish the Top 5 Knowledge & Skills survey as it will help me generate my impact narrative. Highlight how it can reveal insights into my contributions.",
            
            module: "Knowledge_Skills"
        },
        {
            title: "Complete Top 5 Knowledge & Skills Survey",
            ai_context: "Prompt me to complete the Top 5 Knowledge & Skills survey to create an impact narrative. Discuss how understanding my skills can lead to better opportunities.",
            
            module: "Knowledge_Skills"
        }
    ],
    "impact_narrative_summary": [
        {
            title: "Impact Narrative",
            ai_context: "Analyze my impact narrative and make modifications to the existing one if needed. my impact narrative is {{dynamic_1}}",
            
            dynamic_data:1,
            module: "ImpactNarrative"
        } 
    ],
    "impact_narrative_compare": [
        {
            title: "Impact Narrative Comparison",
            ai_context: "Compare impact narrative of {{dynamic_1}} : {{dynamic_2}} with {{dynamic_3}}'s impact narrative {{dynamic_4}}",
            dynamic_data:4,
            module: "ImpactNarrative"
        } 
    ],
   
    "meeting_soul_summary":[
        {
            title: "Meeting Summary",
            ai_context: "Generate a summary for meeting {{dynamic_1}}, including highlights from previous meetings.",
            
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            ai_context: "Provide a detailed summary of meeting {{dynamic_1}} and include a recap of prior meetings.",
            
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            ai_context: "Please summarize the discussion points from meeting {{dynamic_1}} along with a brief on previous meetings.",
            
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            ai_context: "Can you create a summary for meeting {{dynamic_1}}? Also, include key points from earlier meetings.",
            
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            ai_context: "Generate a summary for meeting {{dynamic_1}} and provide an overview of previous meetings' outcomes.",
            
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            ai_context: "Summarize the main takeaways from meeting {{dynamic_1}}, and include a summary of earlier meetings.",
            
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            ai_context: "Please give a comprehensive summary of meeting {{dynamic_1}}, as well as notes from past meetings.",
            
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            ai_context: "Create a summary of the topics covered in meeting {{dynamic_1}}, plus an overview of previous meetings.",
            
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            ai_context: "Generate a meeting summary for {{dynamic_1}} and include relevant points from earlier meetings.",
            
            dynamic_data: 1
        },
        {
            title: "Meeting Summary",
            ai_context: "Provide a summary for the recent meeting {{dynamic_1}} with a recap of prior meetings for context.",
            
            dynamic_data: 1
        }
    ],
    "one_on_one_add_action_item":[
        {
            title: "One On One Create Action Item",
            ai_context: "Help me create an action item for my one-on-one meeting.",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            ai_context: "Assist in defining an action item for my recent one-on-one.",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            ai_context: "Can you help me outline an action item from my one-on-one session?",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            ai_context: "Please assist me in setting up an action item for my one-on-one meeting.",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            ai_context: "Help me establish a follow-up action item from my one-on-one.",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            ai_context: "Could you guide me in creating an action item for my latest one-on-one?",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            ai_context: "Assist me in formulating an action item based on my one-on-one discussion.",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            ai_context: "Please help me draft an actionable step from my one-on-one meeting.",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            ai_context: "Can you support me in developing an action item following my one-on-one?",
            from_cache: true
        },
        {
            title: "One On One Create Action Item",
            ai_context: "Guide me in creating an action item from my one-on-one session.",
            from_cache: true
        }
    ],
    "one_on_one_create_feedback":[
        {
            title: "One On One Create Feedback",
            ai_context: "Help me add feedback for my one-on-one meeting.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            ai_context: "Assist me in providing feedback from my recent one-on-one session.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            ai_context: "Could you guide me in adding feedback based on my one-on-one meeting?",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            ai_context: "Please help me draft feedback following my one-on-one meeting.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            ai_context: "Assist me in documenting feedback from my one-on-one session.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            ai_context: "Can you support me in adding feedback notes for my one-on-one meeting?",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            ai_context: "Help me write feedback based on my one-on-one discussion.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            ai_context: "Please assist me in recording feedback for my recent one-on-one.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            ai_context: "Guide me in creating feedback points from my one-on-one session.",
            from_cache: true
        },
        {
            title: "One On One Create Feedback",
            ai_context: "Can you help me finalize feedback notes from my one-on-one?",
            from_cache: true
        }
    ],
    "create_idp_tip": [
        {
            title: "Creating Individual Development Plan",
            ai_context: `I am defining a {{dynamic_1}} individual development plan to {{dynamic_2}}. I need to the know the pre-requsite or skills that I need before start the learning. I am also interested to know the development activities that i need to consider and the resources available for self learning.`,
            dynamic_data:2
        }
    ],
    "idp_detail_summary": [
        {
            title: "Viewing IDP Detail",
            ai_context: `Give some valuable tips by accessing {{dynamic_1}} idp detail`, 
            dynamic_data:1 
        }
    ],
    "manager_idp_pending_approval_due_passed": [
        {
            title: "Due date of reportee's IDP passed",
            ai_context: `As a manager i haven't approved few of my reportee's idp such as {{dynamic_1}}. But the due date has been passed. `,
            dynamic_data:1
        }
    ],
    "manager_idp_pending_approval_due_upcoming": [
        {
            title: "Approving reportee's IDP",
            ai_context: `As a manager i  have to approve my reportee's idp such as {{dynamic_1}}. What all steps i need to consider before approving my reportee's idp`,
            dynamic_data:1
        }
    ],
    "manager_idp_inprogress_due_passed": [
        {
            title: "IDP due date passed for reportee",
            ai_context: `As a manager what actions should i take if my reportees have not completed the idp within the due date. list of idp {{dynamic_1}}`,
            dynamic_data:1
        }
    ],
    "commitment_followup_date_upcoming": [
        {
            title: "Commitment Follow Up Date Upcoming",
            ai_context: "Based on my coaching commitment follow up date approaching, suggest actionable tips to help me complete them effectively. The goal statement list is {{dynamic_1}}.",
            
            dynamic_data:1
        } 
    ],
    "commitment_detail_action_item": [
        {
            title: "Viewing actionable tips for completing coaching commitments",
            ai_context: "With my coaching commitment action items' due date approaching, provide actionable tips to help me complete them effectively. The action items list is {{dynamic_1}}.",
            
            dynamic_data:1
        } 
    ],
    "agenda_review": [
        {
            title: "Viewing meeting agenda",
            ai_context: "Provide tips by analyzing the meeting agenda",
            from_cache: true
        }
    ],
    "previous_meeting_action_item": [
        {
            title: "Previous Meeting Action Item",
            ai_context: "Give tips regarding the action items discussed in the previous meeting",
            from_cache: true
        }
    ],
}

export const basic_ai_prompt = `You are a helpful assistant who provides quick, actionable
tips for managers and employees in one sentence. These tips should be clear,
concise, and directly applicable in the moment. Max character must be less than 500.`
