import gql from "graphql-tag";

export const USER_BASIC_DETAIL = gql`
query myprofile($personality_survey: ID,$strength_survey: ID,$value_survey: ID,$personality_questions:[ID],$diversity_survey: ID,$diversity_questions:[ID]){
    me{
        id
        firstName
        lastName
        email
        profile{
            id
            profileImage
        }
        employee{
            id
            memberSet(role_Isnull:false,last:1){
                edges{
                    node{
                        id
                        role{
                            id
                            title
                            employeeLevel {
                              id
                              name
                            }
                            jobfamilySet(last:1){
                                edges{
                                    node{
                                        id
                                        title
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        strength: responseSet( survey: $strength_survey,last:1) {
        edges {
          node {
            id
            answerSet(first:3) {
              edges {
                node {
                  id
                  answer
                }
              }
            }
          }
        }
      }

      value: responseSet( survey: $value_survey,last:1) {
        edges {
          node {
            id
            answerSet (first:3){
              edges {
                node {
                  id
                  answer
                }
              }
            }
          }
        }
      }

      diversity: responseSet( survey: $diversity_survey,last:1) {
        edges {
          node {
            id
            answerSet(question_In:$diversity_questions) {
              edges {
                node {
                  id
                  question{
                    id
                  }
                  answer
                }
              }
            }
          }
        }
      }

        personality: responseSet( survey: $personality_survey) {
        edges {
          node {
            id
            answerSet(question_In:$personality_questions) {
              edges {
                node {
                  id
                  answer
                  sequence
                  question{
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
}
`

export const CREATE_TIP_SETTINGS =gql`
mutation createTipsettings($active: Boolean!,$module: String!){
  createTipsettings(active:$active,module:$module){
    tipsettings{
      id
		}
  }
}
`
export const UPDATE_TIP_SETTINGS =gql`
mutation updateTipsettings($active: Boolean!,$id: ID!){
  updateTipsettings(active:$active,id:$id){
    tipsettings{
      id
		}
  }
}
`
export const TIP_SETTINGS_LIST =gql`
query tipSettingsByUser{
tipSettingsByUser{
     edges{
      node{
        id
        module
        active
      }
    }
  }
}
`

export const DEFAULT_NOTIFICATION_SETTINGS =gql`
query notificationsSettingsDefault{
  notificationsSettingsDefault{
    okrSummary
    okrKr
    okrMilestone
    okrObjective
    okrDueDate
    feedbackReminder
    meetingReminder1on1
    idpDueDate
  }
}
`

export const USER_NOTIFICATION_SETTINGS =gql`
query notificationsSettings{
  OKR_SUMMARY:notificationsSettings(module_Istartswith:"OKR_SUMMARY",first:1){
    edges{
      node{
        id
        duration
        durationSpecifier
        module
      }
    }
  }

  OKR_KR:notificationsSettings(module_Istartswith:"OKR_KR",first:1){
    edges{
      node{
        id
        duration
        durationSpecifier
        module
      }
    }
  }

  OKR_MILESTONE:notificationsSettings(module_Istartswith:"OKR_MILESTONE",first:1){
    edges{
      node{
        id
        duration
        durationSpecifier
        module
      }
    }
  }

  OKR_OBJECTIVE:notificationsSettings(module_Istartswith:"OKR_OBJECTIVE",first:1){
    edges{
      node{
        id
        duration
        durationSpecifier
        module
      }
    }
  }

  OKR_DUE_DATE:notificationsSettings(module_Istartswith:"OKR_DUE_DATE",first:1){
    edges{
      node{
        id
        duration
        durationSpecifier
        module
      }
    }
  }

  FEEDBACK_REMINDER:notificationsSettings(module_Istartswith:"FEEDBACK_REMINDER",first:1){
    edges{
      node{
        id
        duration
        durationSpecifier
        module
      }
    }
  }

  MEETING_REMINDER_1ON1:notificationsSettings(module_Istartswith:"MEETING_REMINDER_1ON1",first:1){
    edges{
      node{
        id
        duration
        durationSpecifier
        module
      }
    }
  }

  IDP_DUE_DATE:notificationsSettings(module_Istartswith:"IDP_DUE_DATE",first:1){
    edges{
      node{
        id
        duration
        durationSpecifier
        module
      }
    }
  }
}
`

export const CREATE_EMAIL_NOTIFICATION_SETTINGS =gql`
mutation createNotificationSettings($notificationSettingsData: [NotificationSettingsInput]!){
  createNotificationSettings(notificationSettingsData:$notificationSettingsData ){
    notifications{
      id
		}
  }
}
`