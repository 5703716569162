// @ts-nocheck
import React from 'react';
import { compose, getCookieFlag, setCookieFlag } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import { getOKRById, withCreateDiscardRequest, withCreateOKR, withDeleteDiscardRequest, withDeleteOKR, withUpdateCorrectionDeadline, withUpdateOKR } from './objective-keyresult-operations';
import ObjectiveDetailView from '../components/ObjectiveDetailView';
import { OKR_CHILD_LIST_QUERY, OKR_CHILD_SUBSCRIPTION } from '../graphql/ChildOKRList.gql';
import { getAiTipCacheTimer, getBase64StringFromInt, getIntFromString, getOKRBase64StringFromInt, globalPermissionValidator, replaceAITipDynamicContent } from 'modules/look';
import { withMe } from 'modules/user/containers/UserOperations';
import moment from 'moment';
import { OKR_BY_ID_QUERY } from '../graphql/OKRById.gql';
import { GET_ENTITY_HEAD_ROLE } from '../graphql/ExtendCorrectionDeadline.gql';
import { BADGE_AWARD_ADD_PERMISSION } from 'config';
import { permissionValidation } from 'modules/look';
import { USER_ROLE } from '../graphql/UserRoles.gql';
import { okr_permission } from 'Permissions/okr.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { kudos_permission } from 'Permissions/kudos.permission';
import { okr_competency_permission } from 'Permissions/okrCompetency.permission';
import { DISCARD_SUBSCRIPTION, DISCARD_SUBSCRIPTION_OKR_DETAILS } from '../graphql/DiscardSubscriptionOkr.gql';
import { CHILD_OKR_CONTRIBUTOR_WEIGHT_QUERY, CHILD_OKR_WEIGHT_SUBSCRIPTION } from '../graphql/ChildOKRContribution.gql';
import { ai_trigger_content } from 'modules/ai-tips/ai-trigger-content';
const ObjectiveDetailContainer = props => {

  const { me, client, userPermission, permissionLoader } = props
  const [allKr, setallkr] = React.useState();
  const [allMs, setallMs] = React.useState([]);
  const [user_level, setUserLevel] = React.useState();
  const [okrById, setokrById] = React.useState();
  const dataRef = React.useRef()
  const dataWightRef = React.useRef()
  const [entityHeadRoles, setEntityHEadRoles] = React.useState();
  const [okrPermitted, setOkrPermitted] = React.useState(false);
  const [permission, setpermission] = React.useState()
  const [allokrweights, setokrweights] = React.useState()
  const [ai_trigger, SetAITrigger] = React.useState()
  const m = React.useRef(true)
  const okrRef = React.useRef(undefined)
  let contributorsSub = undefined
  let contributorsWeightSub = undefined
  let discardSub = undefined;
  let newdiscardSub = undefined;
  React.useEffect(() => {
    return () => {
      if (contributorsSub) {
        contributorsSub.unsubscribe();
      }
      if (newdiscardSub) {
        newdiscardSub.unsubscribe();
      }
      if (contributorsWeightSub) {
        contributorsWeightSub.unsubscribe();
      }
    }
  }, [])

  React.useEffect(() => {
    if (userPermission?.length && !permissionLoader) {
      let okrPermission = globalPermissionValidator(okr_permission, userPermission)
      setOkrPermitted(okrPermission);
      let permission = globalPermissionValidator(kudos_permission, userPermission)
      setpermission(permission)
    }
    else {
      setpermission(null)
    }
  }, [userPermission, permissionLoader])



  React.useEffect(() => {
    if (me && props.match.params && okrPermitted) {
      getUserRole()
      getKRList({ okr_Parent: getBase64StringFromInt('okrType', props.match.params.okrId), createdBy: me?.employee?.id })
      getOKRWeights()
    }
  }, [me, okrPermitted])


  const getUserRole = async () => {

    const { data } = await client.query({
      query: USER_ROLE,
      fetchPolicy: 'network-only',
    });
    if (data?.user_level) {
      setUserLevel(data?.user_level)
    }
  }
  const getKRList = async (filter) => {

    const { data } = await client.query({
      query: OKR_CHILD_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { ...filter, tz: moment.tz.guess() }
    });
    if (data) {
      setallkr(data?.contributors)
      dataRef.current = data?.contributors
      discardSubscription()
      contributorsSub = await client.subscribe({
        query: OKR_CHILD_SUBSCRIPTION,
        variables: filter,
        fetchPolicy: 'network-only'
      }).subscribe({
        next(result) {
          let contributorsCacheData = dataRef.current
          if (result?.data?.contributorSubscription?.contributor?.okrType === 'KEY_RESULT' && result?.data?.contributorSubscription?.contributor?.role === "OWNER" && result?.data?.contributorSubscription?.contributor?.okr?.parent?.id === getBase64StringFromInt('okrType', props.match.params.okrId)) {

            switch (result.data.contributorSubscription.mutation) {
              case 'CREATE':
                const create = contributorsCacheData?.edges?.filter(item =>
                  item.id === result.data?.contributorSubscription?.contributor?.id
                    ? false
                    : true).concat([{ node: result?.data?.contributorSubscription?.contributor }])
                if (m.current) {
                  if (create) {
                    setallkr({ edges: create })
                    dataRef.current = { edges: create }
                  }
                }
                break
              case 'UPDATE':
                if (m.current) {
                  const Update = contributorsCacheData?.edges?.map(item =>
                    item?.node?.id === result?.data?.contributorSubscription.contributor?.id
                      ? { node: result.data.contributorSubscription.contributor }
                      : item
                  )
                  if (Update) {
                    setallkr({ edges: Update })
                    dataRef.current = { edges: Update }
                  }

                }
                break
              case 'DELETE':
                if (m.current) {
                  const deleted = contributorsCacheData?.edges?.filter(item =>
                    item?.node?.id === result?.data?.contributorSubscription.contributor?.id
                      ? false
                      : true
                  )

                  if (deleted) {
                    setallkr(deleted)
                    dataRef.current = deleted
                  }

                }
                break
              default:
                break
            }
          }
        }
      })
    }

  }

  const getOKRWeights = async () => {
    const { data } = await client.query({
      query: CHILD_OKR_CONTRIBUTOR_WEIGHT_QUERY,
      fetchPolicy: 'network-only',
      variables: { okr_Parent: getBase64StringFromInt('okrType', props.match.params.okrId), createdBy: me?.employee?.id }
    });

    if (data?.contributors) {
      setokrweights(data?.contributors)
    }
  }

  const discardSubscription = async () => {
    newdiscardSub = await client.subscribe({
      query: DISCARD_SUBSCRIPTION,
      // variables: { id: discardedOkrId },
      fetchPolicy: 'network-only'
    }).subscribe({
      next(result) {

        switch (result.data?.discardRequestSubscription?.mutation) {
          case 'CREATE': {
            let list = { ...dataRef.current }
            let discarded_okr = result.data?.discardRequestSubscription?.discardRequest?.okr
            let okr_data = list.edges?.find(({ node }) => node?.okr?.id == discarded_okr?.id)

            if (okr_data) {
              okr_data.node.okr.isDiscarded = result.data?.discardRequestSubscription?.discardRequest
              okr_data.node.okr['discardRequestRaised'].totalCount = 1
              setallkr(list)
              dataRef.current = list
            }

            break
          }
          default:
            break
        }
      }
    })
  }


  const getMSList = async (okrId) => {
    let krId = getIntFromString(okrId);
    const { data } = await client.query({
      query: OKR_CHILD_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { okr_Parent: okrId, createdBy: me?.employee?.id }
    });

    if (data) {
      setallMs(data?.contributors);
    }
  }

  const OKR_BY_ID = async () => {
    const { data } = await client.query({
      query: OKR_BY_ID_QUERY,
      fetchPolicy: 'network-only',
      variables: { id: getOKRBase64StringFromInt(props.match.params.okrId), createdBy: me?.employee?.id, requestRaisedBy: me?.employee?.id }
    });

    if (data?.okrById) {

      setokrById(data?.okrById);
      okrRef.current = data?.okrById
      let detail = data?.okrById
      if (data?.okrById?.progress < 100) {
        let cache_data = JSON.stringify({
          optype: "VIEW",
          objectId: data?.okrById?.id,
          type: `okr_summary_${getIntFromString(data?.okrById?.id)}`,
          data: { progress: data?.okrById?.progress,duedate:data?.okrById?.duedate }
        })
        let cached_trigger = await getCookieFlag(`okr_summary_${getIntFromString(data?.okrById?.id)}_${getIntFromString(me?.id)}`)
        if (!cached_trigger || cache_data != cached_trigger) {
          let trigger = {
            trigger: ai_trigger_content.okr_summary,
            objectId: data?.okrById?.id,
            optype: "VIEW", 
          }
          SetAITrigger({ ...trigger })
          setCookieFlag(`okr_summary_${getIntFromString(data?.okrById?.id)}_${getIntFromString(me?.id)}`, cache_data, { expires: getAiTipCacheTimer() })
        }
      }

      discardSub = await client.subscribe({
        query: DISCARD_SUBSCRIPTION_OKR_DETAILS,
        // variables: { id: discardedOkrId },
        fetchPolicy: 'network-only'
      }).subscribe({
        next(result) {
          switch (result.data?.discardRequestSubscription?.mutation) {
            case 'CREATE':
              let discarded_okr = result.data?.discardRequestSubscription?.discardRequest?.okr
              if (okrRef.current?.id === discarded_okr?.id) {
                okrRef.current.discardRequestRaised.totalCount = 1
                okrRef.current.pending = { totalCount: okrRef.current.pending?.totalCount + 1, edges: [{ ...okrRef.current.pending?.edges, node: result.data?.discardRequestSubscription?.discardRequest }] }
                setokrById({ ...okrRef.current })
              }
              break
            case 'UPDATE':

              break
            case 'DELETE':
              let cancel_discarded_okr = result.data?.discardRequestSubscription?.discardRequest?.okr
              if (okrRef.current?.id === cancel_discarded_okr?.id) {
                okrRef.current.discardRequestRaised.totalCount = 0
                okrRef.current.pending = { totalCount: 0, edges: [] }
                setokrById({ ...okrRef.current })
              }
              break
            default:
              break
          }
        }
      })
    }
  }

  React.useEffect(() => {
    if (props.match.params.okrId && me && okrPermitted) {
      OKR_BY_ID()
    }
  }, [me, props.match.params.okrId, okrPermitted])

  React.useEffect(() => {
    if (okrById && me) {
      if (okrById?.owner?.user?.employee?.id == me?.employee?.id) {
        withEntityHeadRoles({ user: me?.id, organizationid: me?.employee?.organizationSet?.edges[0]?.node?.id })
      }
    }
  }, [okrById, me])


  const withEntityHeadRoles = async (filter) => {
    const { data } = await client.query({
      query: GET_ENTITY_HEAD_ROLE,
      fetchPolicy: 'network-only',
      variables: filter
    });

    if (data?.entityHeadRoles) {
      let manager_detail = data?.entityHeadRoles?.employee?.organizationSet?.edges[0]?.node?.employees?.edges[0]?.node

      if (manager_detail && (manager_detail?.orgCeoEmployee?.totalCount || manager_detail?.orgPocEmployee?.totalCount || manager_detail?.teamManagerEmployee?.totalCount || manager_detail?.verticalHeadEmployee?.totalCount))
        setEntityHEadRoles({ isallowed_role: true });
    }

  }

  const updateKRRef = (e) => {
    const { record, type } = e
    let list = { ...dataRef.current }
    let okr_data = list.edges?.find(({ node }) => node?.okr?.id == record?.id)
    if (okr_data) {
      if (type == 'UPDATE') {
        Object.entries(record).forEach(item => {
          if (item[0] != 'id') {
            okr_data.node.okr[item[0]] = item[1]
          }
        })
      }
      else if (type == 'DELETE') {
        list.edges = list.edges?.filter(({ node }) => node?.okr?.id != record?.id)
      }
      dataRef.current = list
    }

  }


  return (
    <>
      <div>
        {
          !permissionLoader ?
            <div>
              {
                (okrPermitted?.requried_permission) ?
                  <ObjectiveDetailView okrById={okrById} refetchOkr={(e) => { OKR_BY_ID() }} {...props} allKr={allKr} getMSList={getMSList} objId={props.match.params.okrId} allMs={allMs}
                    entityHeadRoles={entityHeadRoles}
                    user_level={user_level}
                    permission={permission} setallkr={setallkr}
                    updateKRRef={(e) => updateKRRef(e)}
                    allokrweights={allokrweights}
                    getWightList={() => getOKRWeights()}
                    ai_trigger={ai_trigger}
                    SetAITrigger={SetAITrigger}
                    okrPermitted={okrPermitted}
                  />
                  :
                  <NoPermissionView />
              }
            </div>
            :
            null
        }
      </div>
    </>
  );
};

export default compose(withApollo, withDeleteOKR, withCreateDiscardRequest, withCreateOKR, withUpdateOKR, withDeleteDiscardRequest, withUpdateCorrectionDeadline)(ObjectiveDetailContainer);