// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import AddOneonOneView from '../components/AddOneonOneView'
import { withToolById } from 'modules/tool/containers/ToolOperations';
import { withApollo } from '@apollo/client/react/hoc';
// import { employeeList } from '../../feedback/containers/feedbackOperations'
import { withCreateMeeting, withCreateActionItems, withUpdateActionItems } from './One_on_oneOperations';
import { getMeetingTypeBase64StringFromInt, globalPermissionValidator } from 'modules/look/components/functions';
import { MEETING_BY_ID } from '../graphql/MeetingByid.gql';
import { GET_MANAGER_EMPLOYEE_LIST } from '../graphql/getManagersandEmployee.gql'
import { GET_MANAGER_EMPLOYEE_LIST_SEARCH } from '../graphql/getEmployeSerach.gql';
import { oneonone_permission } from 'Permissions/oneonone.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import moment from 'moment';
import { MEETING_TIME_SLOT } from '../graphql/MeetingtimeSlot.gql';
const AddOneonOneContainer = props => {
  const { client, me, history, location, userPermission } = props
  const [meetingById, setMeeting] = React.useState()
  const [allEmpoloyee, setAllEmpoloyee] = React.useState()
  const [allEmpoloyeeResult, setAllEmpoloyeeResult] = React.useState()
  const [permission, setpermission] = React.useState()
  const [meetingTimeSlotSet,setMeetingTimeSlotset] = React.useState([]);
  const [ai_trigger,SetAITrigger] = React.useState()
  React.useEffect(() => {
  
  if(me) {
      AllEmployeeList()
      checkForAvailabilityOfTimeSlot()
    }
  }, [me])


  React.useEffect(()=>{
    localStorage.removeItem('emailRoute');  
  },[])


  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(oneonone_permission, userPermission)
      setpermission(permission)
    }
    else {
      setpermission(null)
    }
  }, [userPermission])


  const AllEmployeeList = async (searchValue) => {
    const { data } = await client.query({
      query: GET_MANAGER_EMPLOYEE_LIST,
      variables: { user_LastName: searchValue, user_FirstName: searchValue },
      fetchPoicy: 'network-only'
    });
    if (data?.allEmpoloyee) {
      let allEmpoloyee = data?.allEmpoloyee
      console.log('allEmpoloyeenotsearch',allEmpoloyee);
      if (allEmpoloyee) {
        setAllEmpoloyee(allEmpoloyee)
      }
    }
  }

  React.useEffect(() => {
    if (location.search) {
      let data = location.search
      let splitId = data.split('=')
      let id = splitId[1]
      getMeetingDetail(id)
    }
  }, [location])

  const getMeetingDetail = async (value) => {
    let id = getMeetingTypeBase64StringFromInt(value);
    const { data } = await client.query({
      query: MEETING_BY_ID,
      variables: { id: id },
    });
    if (data?.meetingById) {
      setMeeting(data?.meetingById)
    }
  }


  const AllEmployeeListSearch = async (searchValue) => {
    const { data } = await client.query({
      query: GET_MANAGER_EMPLOYEE_LIST_SEARCH,
      variables: { user_LastName: searchValue, user_FirstName: searchValue },
      fetchPoicy: 'network-only'
    });
    if (data) {
      let allEmpoloyee = data
      if (allEmpoloyee) { 
        setAllEmpoloyeeResult(allEmpoloyee)
      }
    }
  }
  const SearchKey = (value) => {
    AllEmployeeListSearch(value)
  }

  const checkForAvailabilityOfTimeSlot = async() =>{
    let currentTime = moment.utc().subtract(8,'h').toISOString();
    if(me?.id){
    const { data } = await client.query({
      query: MEETING_TIME_SLOT,
      variables: { when_Gte:currentTime , member: [me?.employee?.id],endedAt_Isnull:true },
      fetchPoicy: 'network-only'
    });
    if (data) {
      // two dimensional array for start and end time of meeting
      let timeSlots = data?.allMeetings?.edges?.map((timeSlot)=>
      [moment(timeSlot?.node?.when).toISOString(),moment(timeSlot?.node?.when).add((timeSlot?.node?.scheduleDuration/3600) ,"h").toISOString()]
      )
      
       setMeetingTimeSlotset(timeSlots)
    }
  }

  }

  return (
    <>

      {
        (permission && permission?.requried_permission) && (
          <AddOneonOneView {...props} meetingTimeSlotSet = {meetingTimeSlotSet} meetingById={meetingById} allEmpoloyee={allEmpoloyee} searchKey={e => SearchKey(e)} allEmpoloyeeResult={allEmpoloyeeResult}
          ai_trigger={ai_trigger}
          SetAITrigger={SetAITrigger}
          permission={permission}></AddOneonOneView>
        )
      }

      {
        (permission && !permission?.requried_permission) && (
          <NoPermissionView />
        )
      }
    </>
  );

};

export default compose(withApollo, withCreateMeeting, withCreateActionItems, withUpdateActionItems)(AddOneonOneContainer);
