// @ts-nocheck
import React, { useState } from 'react';
import { compose, getCookieFlag, setCookieFlag } from '../../core';
import OKRTableView from '../components/OKRTableView';
import { withCreateContributor, withCreateOKR, withUpdateOKR } from './objective-keyresult-operations';
import { withApollo } from '@apollo/client/react/hoc';
import { OKR_LIST_QUERY } from '../graphql/OKRList.gql';
import { CONTRIBUTORS_SUBSCRIPTION } from '../graphql/contributorsSubscription.gql';
import moment from 'moment';
import { } from 'config';
import { BADGE_AWAED_ADD_USER_PERMISSION, BADGE_AWARD_ADD_PERMISSION, BADGE_AWARD_VIEW_PERMISSION } from "../../../config";
import { getAiTipCacheTimer, getIntFromString, globalPermissionValidator, permissionValidation } from 'modules/look';
import { kudos_permission } from 'Permissions/kudos.permission';
import { DISCARD_SUBSCRIPTION } from '../graphql/DiscardSubscriptionOkr.gql';
import { OKR_SUBSCRIPTION } from '../graphql/okrSubscription.gql';
import { ai_trigger_content } from 'modules/ai-tips/ai-trigger-content';

const OkrTableContainer = props => {
  const { me, client, userPermission, SetAITrigger } = props
  const [loader, setloader] = React.useState(true)
  const [allobjective, setallobjective] = React.useState()
  const [pageInfo, setpageInfo] = React.useState()
  const [incomingData, setIncommingData] = React.useState()
  const [okr_Duedate_Range, setDateRange] = React.useState()
  const [permission, setpermission] = React.useState()
  const [milestoneDiscardState, setMilestoneDiscardState] = useState()
  const [keyResultDiscardState, setKeyResultDiscardState] = useState()
  // const [ mile ]
  const dataRef = React.useRef()
  const page_info_ref = React.useRef()

  React.useEffect(() => {
    page_info_ref.current = pageInfo
  }, [pageInfo])

  const m = React.useRef(true)
  const contributorsSub_api = React.useRef(false)
  const okrSubscription_api = React.useRef(false)
  const discardSub_api = React.useRef(false)
  let contributorsSub = undefined;
  let okrSubscription = undefined;
  let discardSub = undefined;
  React.useEffect(() => {
    return () => {

      if (contributorsSub) {
        contributorsSub.unsubscribe();
        contributorsSub = undefined
        contributorsSub_api.current = false
      }
      if (okrSubscription) {
        okrSubscription.unsubscribe()
        okrSubscription = undefined
        okrSubscription_api.current = false
      }

      if (discardSub) {
        discardSub.unsubscribe()
        discardSub = undefined
        discardSub_api.current = false
      }
    }
  }, [])

  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])




  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(kudos_permission, userPermission)
      setpermission(permission)
    }
    else {
      setpermission(null)
    }
  }, [userPermission])



  const getObjectiveList = async (filter) => {
    setloader(true)
    const { data } = await client.query({
      query: OKR_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: { first: 10, createdBy: me?.employee?.id, ...filter, tz: moment.tz.guess(), recipient: me?.employee?.id }
    });
    if (data) {

      let collectiveObjs = [...data?.AllCascadingObjectives?.edges, ...data?.AllNonCascadingObjectives?.edges]
      let incomingDataFromQuery = collectiveObjs?.map(({ node }) => {
        return {
          id: node?.okr?.id,
          title: node?.okr?.title,
          role: node?.role,
          // okrType:node?.okrType,
          childSet: node?.okr?.childSet?.totalCount ? true : false,
          startdate: node?.okr?.startdate,
          duedate: node?.okr?.duedate,
          goalStatement: node?.okr?.goalStatement,
          correctionDeadline: node?.okr?.correctionDeadline,
          badge_award: node?.okr?.badgeAward?.edges,
          contributors: node?.okr?.contributorSet?.edges?.map(({ node }) => {
            return {
              ...node?.employee, role: node?.role
            }
          }),
          progress: node?.progress ?? 0,
          discardRequestRaised: node?.okr?.discardRequestRaised.totalCount > 0 ? true : false,
          isDiscarded: {
            status: node?.okr?.isDiscarded?.status ?
              node?.okr?.isDiscarded?.status :
              node?.okr?.discardRequest?.totalCount ? "PENDING" : '',
            //     id:node?.okr?.isDiscarded?.id?node?.okr?.isDiscarded?.id:
            //   node?.okr?.discardRequest?.totalCount?node?.okr?.discardRequest?.edges[node?.okr?.discardRequest?.edges?.length-1]?.node?.id:''
          }
        }
      })

      let objective = {
        totalCount: data?.AllCascadingObjectives?.totalCount + data?.AllNonCascadingObjectives?.totalCount,
        nextPage: (data?.AllCascadingObjectives?.pageInfo?.hasNextPage || data?.AllNonCascadingObjectives?.pageInfo?.hasNextPage) ? true : false,
        from_search: filter?.from_search,
        from_pagination: filter?.from_pagination
      }
      let objective_list = []
      objective.edges = []
      if (!filter.from_pagination) {
        objective_list = incomingDataFromQuery
      } else {
        objective_list = [...dataRef.current['edges'], ...incomingDataFromQuery]
      }
      let duplicate_list = []
      objective_list.forEach(element => {
        if (!duplicate_list.includes(element?.id)) {
          duplicate_list.push(element?.id)
          objective.edges.push(element)
        }
      });
      dataRef.current = objective
      setallobjective(objective)
      if (filter?.ai_trigger) {
        let objective_list = objective?.edges
        let cached_trigger_okr_due_date_passed = await getCookieFlag(`okr_due_date_passed_${getIntFromString(me?.id)}`)
        let cached_trigger_okr_due_date_upcoming = await getCookieFlag(`okr_due_date_upcoming_${getIntFromString(me?.id)}`)
        if (!cached_trigger_okr_due_date_upcoming && objective_list?.some(i => {
          let date_gap = moment(i?.duedate).endOf("day").diff(moment().endOf("day"), "day", true)
          if (date_gap >= 0 && date_gap <= 5 && i?.progress != 100) {
            return true
          } else {
            return false
          }
        })) {
          let due_date_upcoming_okr = objective_list?.filter(i => {
            let date_gap = moment(i?.duedate).endOf("day").diff(moment().endOf("day"), "day", true)
            if (date_gap >= 0 && date_gap <= 5 && i?.progress != 100) {
              return true
            } else {
              return false
            }
          })
          let dynamic_data = due_date_upcoming_okr?.map(i => i?.title)?.slice(0, 2)?.join(",")
          let trigger = {
            trigger: ai_trigger_content.okr_due_date_upcoming,
            optype: "LIST",
            dynamic_data: [dynamic_data]
          }
          SetAITrigger({ ...trigger })
          setCookieFlag(`okr_due_date_upcoming_${getIntFromString(me?.id)}`, JSON.stringify({
            optype: "LIST",
            type: "okr_due_date_upcoming"
          }), { expires: getAiTipCacheTimer() })


        }
        else if (!cached_trigger_okr_due_date_passed && objective_list?.some(i => moment(i?.duedate).isBefore(moment()) && i?.progress != 100)) {
          let due_date_passed_okr = objective_list?.filter(i => moment(i?.duedate).isBefore(moment()) && i?.progress != 100)
          let dynamic_data = due_date_passed_okr?.map(i => i?.title)?.slice(0, 2)?.join(",")

          let trigger = {
            trigger: ai_trigger_content.okr_due_date_passed,
            optype: "LIST",
            dynamic_data: [dynamic_data]
          }
          SetAITrigger({ ...trigger })
          setCookieFlag(`okr_due_date_passed_${getIntFromString(me?.id)}`, JSON.stringify({
            optype: "LIST",
            type: "okr_due_date_passed"
          }), { expires: getAiTipCacheTimer() })


        }
      }
      setpageInfo({ ...{ totalCount: objective?.totalCount, nextPage: objective?.nextPage } })
      setDateRange(filter?.okr_Duedate_Range)
      subscribeToMore()
    }
  }

  const subscribeToMore = () => {
    if (!okrSubscription_api.current) {
      okrSubscription_api.current = true
      okrSubscriptionMutation()
    }

    if (!contributorsSub_api.current) {
      contributorsSub_api.current = true
      okrCreateSubscriptionMutation()
    }

    if (!discardSub_api.current) {
      discardSub_api.current = true
      discardSubscriptionMutation()
    }

  }

  const discardSubscriptionMutation = async () => {
    discardSub = await client.subscribe({
      query: DISCARD_SUBSCRIPTION,
      // variables: { id: discardedOkrId },
      fetchPolicy: 'network-only'
    }).subscribe({
      next(result) {
        switch (result.data?.discardRequestSubscription?.mutation) {
          case 'CREATE':
            let okr_type = result.data?.discardRequestSubscription?.discardRequest?.okr?.okrType
            let okr_detail = result.data?.discardRequestSubscription?.discardRequest?.okr
            if (okr_type === "OBJECTIVE" || (okr_type !== "OBJECTIVE" && okr_detail?.owner?.id != me?.employee?.id)) {
              let record = dataRef.current?.edges?.find((value) => value?.id === result.data?.discardRequestSubscription?.discardRequest?.okr?.id)
              if (record) {
                record.discardRequest = result.data?.discardRequestSubscription?.discardRequest
                record.discardRequestRaised = true
                record.isDiscarded = { status: "PENDING" }
              }
              setallobjective({ ...dataRef.current, from_sub: true })

            }
            else if (okr_type === "KEY_RESULT") {
              setKeyResultDiscardState(result.data?.discardRequestSubscription)
            } else if (okr_type === "MILESTONE") {
              setMilestoneDiscardState(result.data?.discardRequestSubscription)
            }
            break
          default:
            break
        }
      }
    })
  }

  const okrCreateSubscriptionMutation = async () => {
    contributorsSub = await client.subscribe({
      query: CONTRIBUTORS_SUBSCRIPTION,
      variables: { first: 10, createdBy: me?.employee?.id },
      fetchPolicy: 'network-only'
    }).subscribe({
      next(result) {
        let contributorsCacheData = dataRef.current

        switch (result.data.contributorSubscription.mutation) {
          case 'CREATE':
            let okr = result?.data?.contributorSubscription?.contributor?.okr
            let collab = okr?.contributorSet?.edges?.find(({ node }) => node?.role == "OWNER")
            let valid_okr = true
            if (okr.okrType != 'OBJECTIVE') {
              if (!collab) {
                valid_okr = false
              } else {
                if (collab?.node?.employee?.id == me?.employee?.id) {
                  valid_okr = false
                }
              }
            }
            if (result.data?.contributorSubscription?.contributor?.okrType == "OBJECTIVE" && valid_okr) {
              result.data.contributorSubscription.contributor.okr['contributors'] = okr?.contributorSet?.edges?.map(({ node }) => {
                return {
                  ...node?.employee,
                  role: node?.role
                }
              })
              const create = contributorsCacheData?.edges?.filter(item =>
                item.id === result.data?.contributorSubscription?.contributor?.id
                  ? false
                  : true).concat([result?.data?.contributorSubscription?.contributor?.okr])


              if (m.current) {
                if (create) {
                  console.log('contributorsCacheData', contributorsCacheData, create);
                  let incomingData = create?.map((node) => {

                    return {
                      id: node?.id,
                      title: node?.title,
                      role: 'OWNER',
                      // okrType:node?.okrType,
                      childSet: node?.childSet || false,
                      startdate: node?.startdate,
                      duedate: node?.duedate,
                      goalStatement: node?.goalStatement,
                      correctionDeadline: node?.correctionDeadline,
                      badge_award: node?.badge_award || node?.badgeAward?.edges,
                      contributors: node?.contributors,
                      progress: node?.progress ?? 0,
                      discardRequestRaised: node?.okr?.discardRequestRaised.totalCount > 0 ? true : false,
                      isDiscarded: {
                        status: node?.isDiscarded?.status ?
                          node?.isDiscarded?.status :
                          node?.discardRequest?.totalCount ? "PENDING" : '',
                        //     id:node?.okr?.isDiscarded?.id?node?.okr?.isDiscarded?.id:
                        //   node?.okr?.discardRequest?.totalCount?node?.okr?.discardRequest?.edges[node?.okr?.discardRequest?.edges?.length-1]?.node?.id:''
                      },
                      createdAt: node?.createdAt
                    }
                  })

                  if (incomingData) {
                    let setObj = new Set();
                    let dataList = incomingData.reduce((acc, item) => {
                      if (!setObj.has(item?.id)) {
                        setObj.add(item?.id)
                        acc.push(item)
                      }
                      return acc;
                    }, [])
                    let sortData = dataList?.sort((a, b) => {
                      return new Date(b['createdAt']) - new Date(a['createdAt'])
                    }
                    )
                    setallobjective({ edges: sortData, from_sub: true })
                    dataRef.current = { edges: incomingData }
                    setpageInfo({ ...{ totalCount: page_info_ref.current?.totalCount + 1, nextPage: page_info_ref.current?.nextPage } })
                  }

                }

              }
            }
            break
          default:
            break
        }
      }
    })
  }

  const okrSubscriptionMutation = async () => {
    okrSubscription = await client.subscribe({
      query: OKR_SUBSCRIPTION,
      variables: { createdBy: me?.employee?.id, tz: moment.tz.guess(), recipient: me?.employee?.id },
      fetchPolicy: 'network-only'
    }).subscribe({
      next(result) {
        let contributorsCacheData = dataRef.current
        let okr_detail = result.data.okrSubscription?.okr
        let okr_edges = contributorsCacheData?.edges
        switch (result.data.okrSubscription.mutation) {
          case 'UPDATE':
            if (okr_edges?.length) {
              const updated = okr_edges?.map(item =>
                item.id === result.data.okrSubscription?.okr.id
                  ? {
                    id: result?.data?.okrSubscription?.okr?.id,
                    title: result?.data?.okrSubscription?.okr?.title,
                    role: 'OWNER',
                    // okrType:node?.okrType,
                    childSet: result?.data?.okrSubscription?.okr?.childSet || false,
                    startdate: result?.data?.okrSubscription?.okr?.startdate,
                    duedate: result?.data?.okrSubscription?.okr?.duedate,
                    goalStatement: result?.data?.okrSubscription?.okr?.goalStatement,
                    correctionDeadline: result?.data?.okrSubscription?.okr?.correctionDeadline,
                    badge_award: result?.data?.okrSubscription?.okr?.badge_award || result?.data?.okrSubscription?.okr?.badgeAward?.edges,
                    contributors: result?.data?.okrSubscription?.okr?.contributors,
                    progress: result?.data?.okrSubscription?.okr?.progress ?? 0,
                    discardRequestRaised: result?.data?.okrSubscription?.okr?.discardRequestRaised.totalCount > 0 ? true : false,
                    isDiscarded: {
                      status: result?.data?.okrSubscription?.okr?.isDiscarded?.status ?
                        result?.data?.okrSubscription?.okr?.isDiscarded?.status :
                        result?.data?.okrSubscription?.okr?.discardRequest?.totalCount ? "PENDING" : '',
                      //     id:node?.okr?.isDiscarded?.id?node?.okr?.isDiscarded?.id:
                      //   node?.okr?.discardRequest?.totalCount?node?.okr?.discardRequest?.edges[node?.okr?.discardRequest?.edges?.length-1]?.node?.id:''
                    },
                    createdAt: result?.data?.okrSubscription?.okr?.createdAt
                  }
                  : item
              )
              contributorsCacheData['from_sub'] = false
              contributorsCacheData['from_pagination'] = false
              contributorsCacheData['edges'] = updated
              console.log("update sub", contributorsCacheData, result.data.okrSubscription)
              setallobjective({ ...contributorsCacheData })
              dataRef.current = contributorsCacheData
              // }
            }
            break
          case 'DELETE':
            if (okr_edges?.length) {
              let doc = okr_edges?.find(item => item?.id == okr_detail?.id)
              let child_list = okr_detail?.childSet?.edges?.map(({ node }) => node?.id) || []
              child_list.push(okr_detail?.id)
              let count = okr_edges?.filter(item => child_list?.includes(item?.id))?.length
              if (doc) {
                okr_edges = okr_edges?.filter(item => !child_list?.includes(item?.id))
                contributorsCacheData['from_sub'] = false
                contributorsCacheData['from_pagination'] = false
                contributorsCacheData['edges'] = okr_edges
                setallobjective({ ...contributorsCacheData })
                dataRef.current = contributorsCacheData
                updatePagination('DELETE', count)
              }
            }
            break
          default:
            break
        }
      }
    })
  }

  React.useEffect(() => {
    if (me) {
      // let cachedDateFilter = JSON.parse(localStorage.getItem('DateFilterOKR'))
      let date_range =
        // cachedDateFilter ?
        //   [
        //     cachedDateFilter?.startDate, cachedDateFilter?.endDate
        //   ]
        //   :
        [
          moment().startOf('year').utc().toISOString(),
          moment().endOf('year').utc().toISOString()
        ]

      getObjectiveList({ offset: 0, okr_Duedate_Range: date_range, ai_trigger: true })
    }
  }, [me])

  const updatePagination = (type, count) => {
    if (type == 'DELETE') {
      setpageInfo({ ...{ totalCount: page_info_ref.current?.totalCount - (count || 1), nextPage: page_info_ref.current?.nextPage } })
    }
  }

  return (
    <>
      <OKRTableView milestoneDiscardState={milestoneDiscardState} updatePagination={(e) => { updatePagination(e) }} keyResultDiscardState={keyResultDiscardState} setKeyResultDiscardState={setKeyResultDiscardState} okr_Duedate_Range={okr_Duedate_Range} dataObjectiveRef={dataRef} setallobjective={setallobjective} setpageInfo={setpageInfo} allobjective={allobjective} loader={loader} setloader={(e) => setloader(e)} paginated_objective={(e) => { getObjectiveList(e) }} filterOkr={(e) => getObjectiveList(e)} pageInfo={pageInfo} incomingData={incomingData} {...props} permission={permission} />
    </>
  );
};

export default compose(withApollo, withCreateOKR, withCreateContributor, withUpdateOKR)(OkrTableContainer);
