// @ts-nocheck
import React from "react";
import { Row, Col, Button, Input, Radio, Space, Dropdown, Checkbox, Menu, Spin, Modal, Collapse, Tabs,Tooltip} from "antd";
import styled from "styled-components";
import { LeftOutlined, EditOutlined, DeleteOutlined, RightOutlined } from "@ant-design/icons";
import { MetaTags, getIntFromString, scrolltoview, ReactMarkdown } from "../../look";
import { displayUtcToUserTimezone, getAiTipCacheTimer, replaceAITipDynamicContent } from '../../look/components/functions';
import ProfileLogo from '../../assets/avatar.jpg';
import AdditionalDetail from './additionalDetailBasicView'
import { ONEONONE_ADD_EDIT_TOOL_ID, FEEDBACK_TOOL_ID, ONEONONE_HOME_TOOL_ID, GOAL_HOME_TOOL_ID } from '../../../config'
import ActionDetailContainer from "../containers/ActionDetailContainer";
import moment, { months } from "moment-timezone";
import { useHistory } from "react-router-dom";
import Home_soul from '../../assets/home-icon-soul.svg'
import Right_arow from '../../assets/right-arrow-soul.svg'
import Back_icon from '../../assets/soul-back.svg'
import creator from '../../assets/one-on-one-creator.svg'
import assigne from '../../assets/one-on-one-contributor.svg'
import AgendaListContainer from "../containers/agendaListContainer";
import ActionItemsListContainer from "../containers/actionItemsListContainer";
import meeting_left_arrow from '../../assets/meeting-left-arrow.svg'
import meeting_right_arrow from '../../assets/meeting-right-arrow.svg'
import right_arrow from '../../assets/right-arrow.svg'
import Dropdown_arrow from '../../assets/arrow-down-one-on-one.svg';
import calender_icon from '../../assets/meeting-calender-icon.svg'
import { CaretDownOutlined } from "@ant-design/icons";
import MeetingFeedbackContainer from '../containers/meetingFeedbackContianer'
import yellowStart from '../../assets/yellowStart.svg'
import graystar from '../../assets/graystar.svg'
import message_inactive from '../../assets/message-view-icon.svg'
import Account from '../../assets/Account.svg'
import message_active from '../../assets/message-view-active-icon.svg'
import TranscriptionSummaryContainer from "../containers/TranscriptionSummaryContainer";
import '../css/one-on-one-meeting-view.css';
import one_on_one_route from '../route/index'
import { ai_trigger_content } from "modules/ai-tips/ai-trigger-content";
import MeetingAiTipContainer from "modules/ai-tips/container/meeting-ai-tip-container";
import { getCookieFlag, setCookieFlag } from "modules/core";
const { Panel } = Collapse;
const MeetingView = props => {
    const history = useHistory()
    const { me, meetingById, remove, selfNotes, index, location, meetinglist, currentMeetingIndex, getMeetingDetail, feedbackLoading, meetingFeedback, permission,updateActionItem,SetAITrigger,ai_trigger } = props
    const { TabPane } = Tabs;
    const [createdByMe, setcreatedByMe] = React.useState(false);
    const [agendaList, setagendaList] = React.useState([]);
    const [actionList, setactionList] = React.useState([]);
    const [feedbackList, setfeedbackList] = React.useState([]);
    const [confirmationModalVisible, setdConfirmationModalVisible] = React.useState(false);
    const [actionData, setActionData] = React.useState();
    const [selectedEmployeeForNotes, setSelectedEmpForNotes] = React.useState();
    const [notesToDisplay, setNotesToDisplay] = React.useState();
    const [defaultUser, setDefaultUser] = React.useState();
    const [myFeedbackCount, setMyFeedbackCount] = React.useState()
    const [managerFeedbackCount, setManagerFeedbackCount] = React.useState()
    const [summary_tab, setSummaryTab] = React.useState('normal_summary');
    const [stars, setStars] = React.useState([1, 2, 3, 4, 5])
    const [feedbackDetailModalVisible, setFeedbackDetailModalVisible] = React.useState(false)
    const today = new Date()
    const ai_tip_meeting_detail_ref =React.useRef(false)
    React.useEffect(() => {
        if(meetingById){
            let member_1 =`${meetingById?.member?.edges[0]?.node?.user?.firstName} ${meetingById?.member?.edges[0]?.node?.user?.firstName}`
            let member_2 =`${meetingById?.member?.edges[1]?.node?.user?.firstName} ${meetingById?.member?.edges[1]?.node?.user?.firstName}`

            let action_items =meetingById?.actionitemSet?.edges?.filter(({node})=>(!node?.isAgenda && !node?.completed))?.map(({node})=>node?.title)
            let feedbacks = meetingById?.feedback?.edges?.map(({node})=>node) 
            if(!ai_tip_meeting_detail_ref.current.current && action_items?.length ){ 
                MeetingAiTipTrigger(action_items)
                ai_tip_meeting_detail_ref.current=true
            }
        }
    }, [meetingById])
    const MeetingAiTipTrigger =async(action_items)=>{
        let cache_data = JSON.stringify({
            optype: "CREATE",
            objectId: meetingById?.id,
            userType:"employee",
            type: `meeting_detail_action_item_${getIntFromString(meetingById?.id)}`,
            data: { objectId: getIntFromString(meetingById?.id) }
        })
        let cached_trigger = await getCookieFlag(`meeting_detail_action_item_${getIntFromString(meetingById?.id)}_${getIntFromString(me?.id)}`)

        if (!cached_trigger || cache_data != cached_trigger) {
        let trigger = {
            trigger:ai_trigger_content.meeting_detail_action_item,
            dynamic_data :[action_items],
            optype:"CREATE",
            objectId:meetingById?.id,
            userType:"employee",
        }
        SetAITrigger({...trigger})
        setCookieFlag(`meeting_detail_action_item_${getIntFromString(meetingById?.id)}_${getIntFromString(me?.id)}`, cache_data, { expires: getAiTipCacheTimer() })
    }
    }
    React.useEffect(() => {
        if (meetingFeedback) {
            let manager_feeback = meetingFeedback?.edges?.map(({ node }) => node?.hostSkills);
            let manager_avg = (manager_feeback.reduce((a, b) => a + b, 0) / manager_feeback?.length);
            let my_feeback = meetingFeedback?.edges?.map(({ node }) => node?.meetingEffectiveness)
            let my_feedback_avg = (my_feeback.reduce((a, b) => a + b, 0) / my_feeback?.length);
            setMyFeedbackCount(my_feedback_avg)
            setManagerFeedbackCount(manager_avg)


        }
    }, [meetingFeedback])

    React.useEffect(() => {
        if (meetingById) {

            let defaultEmployeeSelected = meetingById?.member?.edges?.filter((element) =>

                getIntFromString(element?.node.user?.id) === getIntFromString(meetingById?.owner?.user?.id)
            );

            setSelectedEmpForNotes(defaultEmployeeSelected[0])
            if (defaultEmployeeSelected?.length) {
                setDefaultUser(defaultEmployeeSelected[0]?.node?.user?.firstName)
                let notes = defaultEmployeeSelected[0]?.notes
                setNotesToDisplay(notes)

            }
        }


    }, [selfNotes, meetingById])

    React.useEffect(() => {
        if (selectedEmployeeForNotes) {
            let selectedEmployee = selfNotes?.filter((element) => getIntFromString(element?.employee?.user?.id) === getIntFromString(selectedEmployeeForNotes?.node?.user?.id));
            if (selectedEmployee?.length) {
                let notes = selectedEmployee[0]?.notes
                setNotesToDisplay(notes)
            } else {
                setNotesToDisplay(undefined)
            }
        }

    }, [selectedEmployeeForNotes])



    React.useEffect(() => {
        if (meetingById && me) {
            if (me?.employee?.id === meetingById?.owner?.user?.employee?.id) {
                setcreatedByMe(true)
            }
            else {
                setcreatedByMe(false)
            }

            let list = meetingById?.actionitemSet?.edges?.map(({ node }) => node)
            let agendaList = list?.filter(item => item?.isAgenda)
            let actionList = list?.filter(item => !item?.isAgenda)
            let feedbackList = meetingById?.feedback?.edges?.map(({ node }) => node)
            let setAction = []
            actionList.forEach(element => {
                let data =
                {
                    id: element?.id,
                    title: element?.title,
                    content: null,
                    navigateTo: null,
                    notes: element?.notes,
                    okrId:element?.okr,
                    completed:element?.completed
                }
                // if (element?.okr) {
                //     data.content = element?.okr
                // }
                if (element?.commitment) {
                    data.content = element?.commitment?.commitment?.title
                }
                else if (element?.coachingCommitment) {
                    data.content = element?.coachingCommitment?.coachingcommitment?.title
                }
                setAction.push(data)
            });
            let setAgenda = []
            agendaList.forEach(element => {
                let data =
                {
                    id: element?.id,
                    title: element?.title,
                    content: null,
                    navigateTo: null,
                    notes: element?.notes,
                    okrId:element?.okr
                }
                // if (element?.okr) {
                //     data.content = element?.okr?.title
                // }
                if (element?.commitment) {
                    data.content = element?.commitment?.commitment?.title
                }
                else if (element?.coachingCommitment) {
                    data.content = element?.coachingCommitment?.coachingcommitment?.title
                }
                setAgenda.push(data)
            });
            let setFeedback = []
            feedbackList.forEach(element => {
                let data =
                {
                    id: element?.id,
                    title: element?.feedbackTo?.user?.firstName,
                    content: element?.feedbackType,
                    navigateTo: null,
                    response: element?.response,
                    updatedAt: element?.updatedAt
                }
                setFeedback.push(data)
            })
            setagendaList(setAgenda)
            setactionList(setAction)
            setfeedbackList(setFeedback)
        }
    }, [meetingById, me])



    const back = () => {
        if (location?.search) {
            let query = location?.search
            let data = query.split('=')
            if (data[1] == "1_1") {

                history.push(one_on_one_route?.oneOnOneHome?.replace(":type","1_1"))
            }
            else if (data[1] == "future") {
                history.push(one_on_one_route?.oneOnOneHome?.replace(":type","future"))
            }
            else if (data[1] == "upcoming") {
                history.push(one_on_one_route?.oneOnOneHome?.replace(":type","upcoming"))
            }
        }
        else{
            history.goBack()
        }
    
    };

    async function RemoveMeeting(value) {
        setdConfirmationModalVisible(false)
        if (value) {
            remove(meetingById?.id)
        }

    }

    const ReportViewActionItem = (data) => {

        let list = meetingById?.actionitemSet?.edges?.map(({ node }) => node)
        let detail = actionList.find(item => item.id === data?.id)
        let actionData = list?.find(item => item?.id === data?.id)
        if (actionData) {
            actionData.completed = detail?.completed ? true : false
            setActionData(actionData)
        }

    }

    const goHome = () => {
        history.push('/')
    }
    const goGoal = () => {
        history.push(`/page/goal/home/${GOAL_HOME_TOOL_ID}`)
    }
    const roleGoal = () => {
        history.push(`/page/role_goal/home/${GOAL_HOME_TOOL_ID}`)
    }
    const MettingMember = (item) => {
        let membersList = item?.member?.edges?.map(({ node }) => node?.user)
        let list = membersList?.filter((data) => data?.id !== item?.owner?.user?.id)
        return `${list[0]?.firstName} ${list[0]?.lastName}`
    }

    const PreviousMeetings = () => {
        getMeetingDetail(meetinglist[currentMeetingIndex - 1])
    }
    const NextMeetings = () => {
        getMeetingDetail(meetinglist[currentMeetingIndex + 1])
    }
    return (
        <>
            <MetaTags
                title="1:1 meeting view"
                description="This is 1:1 meeting view page."
            />
{permission&&permission?.runpod_permission&&<MeetingAiTipContainer {...props}/>}
            <div style={{ padding: '0px 35px' }}>

                {meetingById && (
                    <>

                        <Row justify="center" align="center" style={{ display: 'flex', flexDirection: 'column', gap: "10px", position: 'relative', marginTop: "10px" }} className="one-on-one-heading-seaction">
                            <img className="back-icon-one-on-one back-arrow-global" style={{ cursor: "pointer", marginLeft: '-2.2em' }} src={Back_icon} alt="" onClick={() => back()} />
                            <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                    <h2 className="one-on-one-head">{meetingById?.title}</h2>
                                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                                        {currentMeetingIndex !== 0 ? <LeftOutlined style={{ color: "#03053D", strokeWidth: 30, fontWeight: "bold", cursor: "pointer" }} onClick={() => PreviousMeetings()} /> :
                                            <LeftOutlined style={{ color: "gray", strokeWidth: 30, fontWeight: "bold" }} />}
                                        <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                                            <h4 className="one-on-one-time-title" style={{ margin: "0", whiteSpace: "nowrap" }}>{displayUtcToUserTimezone(meetingById?.when, "DD MMM YYYY")}</h4>
                                            <img src={calender_icon} alt="" style={{ height: "18px" }} />
                                        </div>

                                        {currentMeetingIndex !== meetinglist?.length - 1 ? <RightOutlined style={{ color: "#03053D", strokeWidth: 30, fontWeight: "bold", cursor: "pointer" }} onClick={() => NextMeetings()} /> :
                                            <RightOutlined style={{ color: "gray", strokeWidth: 30, fontWeight: "bold" }} />}
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <div>
                            <Row align="center" justify="center" style={{ gap: "10px", marginTop: "10px" }}>
                                <p className='user-name meeting-user-name' style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center', gap: "5px", marginTop: "10px" }}><img src={creator} alt="" style={{ height: "20px" }} /><span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{meetingById?.owner?.user?.firstName} {meetingById?.owner?.user?.lastName}</span></p>
                                <p className='user-name meeting-user-name' style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center', gap: "5px", marginTop: "10px" }}><img src={assigne} alt="" style={{ height: "20px" }} /><span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{MettingMember(meetingById)}</span></p>
                            </Row>

                            {meetingFeedback?.totalCount > 0 && <div style={{
                                background: "#F5F5F5",
                                border: "1px solid #D9D9D9",
                                marginBottom: '1em',
                                borderRadius: "8px", padding: "15px 20px 15px 20px",
                            }} className="rating-section">
                                <Row justify="space-between" align="center">
                                    <Row justify="space-between" align="center" style={{ width: "78%" }}>
                                        <Col style={{ display: "flex", flexWrap: "nowrap", alignItems: "center", gap: "10px" }}>
                                            <div className="star-title" style={{ color: 'rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: "20px" }}><span style={{ fontSize: "40px", textAlign: 'center', marginTop: "-20px" }}>.</span>   Your 1:1 Experience Rate  :</div>
                                            <Row>
                                                <div>
                                                    {
                                                        stars?.map((star, index) =>
                                                            <img className="star-rating-effectivness-feedback" style={{ width: "25px", marginBottom: "3px" }} src={((myFeedbackCount / 20) >= (index + 1)) ? yellowStart : graystar} />
                                                        )
                                                    }

                                                </div>
                                            </Row>
                                        </Col>
                                        <Col style={{ display: "flex", flexWrap: "nowrap", alignItems: "center", gap: "10px" }}>
                                            <div className="star-title" style={{ color: 'rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: "20px" }}><span style={{ fontSize: "40px", textAlign: 'center', marginTop: "-20px" }}>.</span>   Manager’s  1:1 Skill Rate :</div>
                                            <Row>
                                                <div>
                                                    {
                                                        stars?.map((star, index) =>
                                                            <img className="star-rating-effectivness-feedback" style={{ width: "25px", marginBottom: "3px" }} src={((managerFeedbackCount / 20) >= (index + 1)) ? yellowStart : graystar} />
                                                        )
                                                    }

                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <img className="message-inactive" style={{ cursor: "pointer" }} src={message_inactive} alt="" onClick={() => setFeedbackDetailModalVisible(true)} />
                                    <Tooltip title="See Detail"><img className="message-active" style={{ cursor: "pointer" }} src={message_active} alt="" onClick={() => setFeedbackDetailModalVisible(true)} /></Tooltip>
                                </Row>
                            </div>}




                            <div style={{
                                background: "#F5F5F5",
                                border: "1px solid #D9D9D9",
                                borderRadius: "8px", padding: "5px 20px 15px 20px",
                            }}>
                                <Row justify="center" align="center" gutter={15}>
                                    <Col lg={12} md={24} sm={24} xs={24} style={{ marginTop: "10px" }} >
                                        <div className="past-meeting-data-container one-on-one-tab one-on-one-meeting-tab" >
                                            <Tabs defaultActiveKey="agenda" centered={true}>
                                                <TabPane tab="AGENDA" key="agenda">
                                                    <AgendaListContainer {...props} addbutton={true} agendaList={agendaList} meetingByIdData={meetingById} noAddOption={true} noMenu={true} />
                                                </TabPane>
                                                {
                                                    permission?.feedback_permission && (
                                                        <TabPane tab="FEEDBACK" key="feedback">
                                                            <MeetingFeedbackContainer {...props} feedbackList={feedbackList} meetingByIdData={meetingById} noAddOption={true} noMenu={true}/>
                                                        </TabPane>
                                                    )
                                                }
                                                <TabPane tab="ACTION ITEM" key="action-item">
                                                    <ActionItemsListContainer  {...props} addbutton={true} actionList={actionList} meetingByIdData={meetingById} noAddOption={true} noMenu={true} statusChange={true}checkSub={true}/>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={12} sm={24} xs={24} style={{ marginTop: "10px" }} >
                                        <div className="past-meeting-data-container" >

                                            {
                                                // (selfNotes?.length) &&
                                                <Row justify="center" align="middle" style={{ marginBottom: '25px', marginTop: '10px' }}>


                                                    <Dropdown className="meeting-notes-dropdown-prev-meeting" overlay={
                                                        <Menu className='meeting-note-dropdown-menu'>
                                                            {meetingById?.member?.edges?.map((member, key) => (
                                                                <Menu.Item className="meeting-note-dropdown-menu-item" onClick={(e) => { setSelectedEmpForNotes(member) }}>{member?.node?.user?.firstName} {member?.node?.user?.lastName}</Menu.Item>

                                                            ))}

                                                        </Menu>} placement="bottomCenter">
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                                                            <div className="past-meeting-data-title" style={{ padding: "0px 0px 0px 0px", marginBottom: '0px' }}>{`${selectedEmployeeForNotes?.node?.user?.firstName}'s notes`}</div>
                                                            <CaretDownOutlined />
                                                        </div>
                                                    </Dropdown>
                                                </Row>
                                            }


                                            {
                                                (selfNotes?.length) ?
                                                    <div className="past-meeting-data-overflow meeting-notes-scroll">
                                                        {
                                                            notesToDisplay ? <p className="meeting-detail-para"><ReactMarkdown style={{ width: '100%' }}>{notesToDisplay}</ReactMarkdown></p> : <h4 className='no-data pt-70' >No Notes Found</h4>
                                                        }
                                                    </div>
                                                    : <div style={{ justifyContent: "center", alignItems: "center", display: "flex", width: "100%" }}>
                                                        <h4 className='no-data pt-70' style={{ color: '#BFBFBF' }}>No Notes Found</h4>
                                                    </div>}
                                        </div>
                                    </Col>
                                    {<Col lg={6} md={12} sm={24} xs={24} style={{ marginTop: "10px" }}>
                                        <div className="past-meeting-data-container meeting-tab" style={{ padding: '10px' }} lg={6} md={10} sm={24} xs={24} >

                                            {
                                                summary_tab === 'normal_summary' && (
                                                    <>
                                                        <h4 className="past-meeting-data-title" style={{ color: "#31D0AA", padding: "0px 0px", marginBottom: '0px' }}>SUMMARY</h4>
                                                        <Row justify="center" align="middle" style={{ gap: '5px', marginBottom: '20px' }}>
                                                            <div align='center' style={{ cursor: "pointer" }} onClick={(e) => setSummaryTab('transcription_summary')} className="transcript-link">Show full transcript</div>
                                                            <img onClick={(e) => setSummaryTab('transcription_summary')} style={{ cursor: "pointer" }} src={right_arrow} />
                                                        </Row>
                                                        <div className="past-meeting-data-overflow" style={{ marginRight: '5px' }}  >
                                                            {
                                                                meetingById?.summary ?
                                                                    <p style={{ padding: '8px 15px 0px 10px' }} className="meeting-detail-para">{meetingById?.summary}</p>
                                                                    : <h4 className="no-data pt-75" style={{ color: "rgb(191, 191, 191)" }}>No Summary Found</h4>
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            }

                                            {
                                                summary_tab === 'transcription_summary' && (
                                                    <>
                                                        <h4 className="past-meeting-data-title" style={{ color: "#31D0AA", padding: "0px 0px", marginBottom: '0px' }}>Transcript</h4>
                                                        <Row justify="center" align="middle" style={{ gap: '5px', marginBottom: '20px' }}>
                                                            <img onClick={(e) => setSummaryTab('normal_summary')} className="summary-arrow" src={right_arrow} />
                                                            <div align='center' style={{ cursor: "pointer" }} onClick={(e) => setSummaryTab('normal_summary')} className="transcript-link">Show summary</div>
                                                        </Row>
                                                        <TranscriptionSummaryContainer {...props} />
                                                    </>
                                                )
                                            }

                                        </div>
                                    </Col>}
                                </Row>
                            </div>

                            <Modal
                                className="feedback-details-modal on-one-close-btn"
                                visible={feedbackDetailModalVisible}
                                footer={null}
                                onCancel={(e) => setFeedbackDetailModalVisible(false)}
                                height={40}
                                width={680}
                            >
                                <Spin spinning={meetingFeedback ? false : true} style={{backgroundColor: 'yellow'}}>
                                    <Col style={{ position: 'relative', top: '3em', fontFamily: 'poppins', display: 'block' }}>
                                        <Row justify="space-between" style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <Row align="bottom" className="modal-feedback-header-for-user" wrap={false}>
                                                <img src={Account} />
                                                <div className="modal-user-name"> {`${meetingFeedback?.edges[0]?.node?.member?.user?.firstName}` + ' ' + `${meetingFeedback?.edges[0]?.node?.member?.user?.lastName}`} </div>
                                            </Row>

                                            <div className="modal-feedback-date" style={{ paddingLeft: "15px" }}>{moment(meetingFeedback?.edges[0]?.node?.createdAt).format('DD-MMM-YYYY')}</div>
                                        </Row>

                                        <Col style={{ paddingLeft: "5px" }}>
                                            <div className="modal-section-header">1:1 experience</div>
                                            <Row>
                                                <div>
                                                    {
                                                        stars?.map((star, index) =>
                                                            <img className="star-rating-effectivness-feedback" src={((myFeedbackCount / 20) >= (index + 1)) ? yellowStart : graystar} />
                                                        )
                                                    }

                                                </div>


                                            </Row>
                                        </Col>

                                        <Col style={{ paddingLeft: "5px" }}>
                                            <div className="modal-section-header">your managers skills</div>
                                            <Row>
                                                <div>
                                                    {
                                                        stars?.map((star, index) =>
                                                            <img className="star-rating-effectivness-feedback" src={((managerFeedbackCount / 20) >= (index + 1)) ? yellowStart : graystar} />
                                                        )
                                                    }
                                                </div>
                                            </Row>
                                        </Col>

                                        <Col style={{ marginBottom: "20px", paddingLeft: "5px" }}>
                                            <div className="modal-section-header">Feedback Notes: </div>
                                            {meetingFeedback?.edges?.map(({ node }) => (
                                                <div className="feedback-note" style={{ paddingBottom: "0px" }}>{node?.suggestions}</div>
                                            ))}

                                        </Col>
                                    </Col>
                                </Spin>

                            </Modal>

                        </div>
                    </>
                )
                }
            </div>
        </>
    )

}
export default MeetingView;

const RowDiv = styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`

const ProfileContainer = styled(Row)`
display:flex;
align-items:center;
gap:15px;
`
