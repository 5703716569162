// @ts-nocheck
import React from "react";
import { Row, Col, Button, Modal } from "antd";
import styled from "styled-components";
const InformationModal = props => {
    const { title, buttonLabel, visible, onConfirm } = props;
    return (
        <>
            <Modal
                footer={null}
                centered
                visible={/* true || */ visible}
                width={"30%"}
                destroyOnClose={true}
                //  onCancel={() => setVisible(false)}
                closable={false}
            //  bodyStyle={{ border: '0px' }}
            >
                <Col>
                    <h3 style={{ fontWeight: 'bold', textAlign: 'center' }}>{title}</h3>

                </Col>
                <div style={{display:'flex',flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"1em",width:"100%"}}>
                    <Button className='sa-create-btn-large' block onClick={() => onConfirm( false )} style={{ width: '75px',fontSize:"1.2em" }}>{buttonLabel}</Button>
                </div>
            </Modal>
        </>
    )

}
export default InformationModal