import React from "react";
import bookmark_disabel from '../../assets/bookmark-inactive.svg'
import { Button, Dropdown, Menu, Popover, Spin,Collapse, Modal, Row,} from "antd";
import three_dot from '../../assets/three-dot.svg'
import okr from '../../assets/okr-redirection.svg'
import edit_icon from '../../assets/edit-agenda.svg'
import delete_icon from '../../assets/delete-agenda.svg'
import AddAgendaContainer from "../containers/AddAgendaContainer";
import { MarkdownField, ReactMarkdown, displayUtcToUserTimezone, getIntFromString } from "modules/look";
import bookmark_link from '../../assets/bookmark-link.svg'
import { useHistory } from "react-router-dom";
import { CaretRightOutlined } from '@ant-design/icons'
import assigne from '../../assets/one-on-one-contributor.svg'
import Agenda_close from '../../assets/agenda-close.svg'
import { OneonOneDeleteModal } from "./one-on-oneDeleteModal";
import { ai_trigger_content } from "modules/ai-tips/ai-trigger-content";
const ActionListView =(props)=>{
    const {actionList,addbutton,meetingByIdData,me,onSubmit,setcloseAddAgenda,createActionItem,updateActionItem,deleteActionItem,noAddOption,noMenu,actionEnable,meetingStartedm,setHideButtonPanel,meetingStarted,hidePlaceHolder,statusChange,checkSub,SetAITrigger}=props
    const { Panel } = Collapse;
    const [memberlist,setmemberlist]= React.useState([]);
    const [addAgenda,setAddAgenda]=React.useState(false)
    const [loading, setloading] = React.useState(false); 
    const [ActionItem, setActionsItem] = React.useState();
    const [addActionItem, setaddActionItem] = React.useState(false);
    const [selectedItem,setSelectedItem]=React.useState()
    const [deleteModal,setDeleteModal]=React.useState(false)
    const [selectedActionItem,setSelectedActionItem]=React.useState()
    const [actionListvalue,setActionList]=React.useState([])
    const history =useHistory()

    React.useEffect (()=>{
      setActionList(actionList)
    },[actionList])
    const text=(data) =>{
        return (
            <>
            <div style={{display:"flex",flexDirection:"row",gap:"10px",justifyContent:"start",alignItems:"center",flexWrap:"wrap"}}>
                <h4 className="action-item-markdown-heades" style={{whiteSpace:"nowrap"}}>Last updated:<span style={{color:"#009AF1",paddingLeft:"5px"}}>{displayUtcToUserTimezone(data?.updatedAt, "DD MMM YYYY")}</span> </h4>
                <h4 className="action-item-markdown-heades" style={{whiteSpace:"nowrap"}}>PROGRESS:<span style={{color:data?.completed?"#4CCB1F":"#E86161",paddingLeft:"5px"}}>{data?.completed?'COMPLETED':'INCOMPLETE'}</span></h4>
                {data?.okrId&&<h4 className="action-item-markdown-heades" style={{whiteSpace:"nowrap"}}><img src={assigne} alt="" /><span style={{color:"#4CCB1F",paddingLeft:"5px"}}>{data?.okrId?.owner?.user?.firstName}</span></h4>}
            </div>
            <div className="action-item-marckdown" style={{background:"#FFF",padding:"10px 20px",border:"1px solid #D9D9D9",borderRadius:"8px",minHeight:'50px',marginTop:"10px"}}>
                <ReactMarkdown >{data?.notes}</ReactMarkdown>
            </div>
            </>
            
        )
    }
    React.useEffect(()=>{
        let memberlist =  meetingByIdData?.member?.edges?.map(({node})=>node.user)
        memberlist=memberlist?.filter(i=>i?.employee?.id!==me?.employee?.id)
        setmemberlist(memberlist)
    },[meetingByIdData])

    const SubmitActionItem=async(data)=>{
        setloading(true)

        if(data?.id){ 
          try{
           const response = await updateActionItem(
             {
              id:getIntFromString(data?.id),
              title:data?.title,
              notes:data?.notes,
              coachingCommitmentId:data?.coachingCommitmentId?.id?getIntFromString(data?.coachingCommitmentId?.id):-1,
              commitmentId: data?.commitmentId?.id?getIntFromString(data?.commitmentId?.id):-1,
              isAgenda:data?.isAgenda,
              okrId: data?.okrId?.id?getIntFromString(data?.okrId?.id):-1,
            });
            if(response && response?.id){
                setAddAgenda(false)
                setSelectedItem()
                setActionsItem(null)
                setHideButtonPanel(false)
                setaddActionItem(false)
                actionEnable(false)
            }
         
          }
          catch{
            setloading(false)
            setActionsItem(null)
            setaddActionItem(false)
          }
          setloading(false)
          setActionsItem(null)
          setaddActionItem(false)
        }
        else{ 
          try{
          let meetingid = getIntFromString(meetingByIdData?.id)
          let actionItemData={
            title: data?.title,
            meetingId: meetingid,
            okrId: data?.okrId?.id?getIntFromString(data?.okrId?.id):null,
            commitmentId: data?.commitmentId?.id?getIntFromString(data?.commitmentId?.id):null,
            coachingCommitmentId: data?.coachingCommitmentId?.id?getIntFromString(data?.coachingCommitmentId?.id):null,
            notes: data?.notes,
            isAgenda: data?.isAgenda,
            employeeId: data?.member?.employee?.id?getIntFromString(data?.member?.employee?.id):getIntFromString(me?.employee?.id)
          }
          const CreateActionItem = await createActionItem({actionItemData});
        
          if (CreateActionItem && CreateActionItem?.id){
            setActionsItem(null)
            setaddActionItem(false)
            setAddAgenda(false)
            setHideButtonPanel(false)
            actionEnable(false)
          }
      
         }
         catch{
          setloading(false)
          setActionsItem(null)
          setaddActionItem(false)
         }
         setloading(false)
         setActionsItem(null)
         setaddActionItem(false)
        }
      
      } 

      const RemoveActionItem = async(value)=>{
        setSelectedActionItem(value)
        setDeleteModal(true)
        setHideButtonPanel(true)
        // setdConfirmationModalVisible(false)
        
      }

      const deleteSelected =async(value)=>{
        if(value){
    
          setloading(true)
          try{
            const response = await deleteActionItem({id:getIntFromString(value)});
            setloading(false)
            setDeleteModal(false)
    
          }
          catch{
            setloading(false)
          }
    
        }
      }

      const EditActionItem=(item)=>{
        setSelectedItem(item)
        setAddAgenda(true)
      }

      const redirectToOkr=(okr)=>{
        if(okr?.okrType === 'OBJECTIVE'){
            history.push(`/role/objective-keyresult/view/${getIntFromString(okr?.id)}`);
        }
        else if(okr?.okrType === 'KEY_RESULT'){
            history.push(`/role/objective-keyresult/keyresult/view/${getIntFromString(okr?.id)}`);
        }
        else if (okr?.okrType === 'MILESTONE'){
            history.push(`/role/objective-keyresult/milestone/view/${getIntFromString(okr?.id)}`);
        }
      }
    const ChangeStatus= async(value)=>{
      console.log('status',value)
      const response = await updateActionItem(
        {
         id:getIntFromString(value?.id),
         completed:value?.status
       });
       if(response&&checkSub){
       let action = actionList.find(item=>item?.id == value?.id)
       action['completed']=response?.completed
        setActionList(actionListvalue?.map(item =>
          item?.id === value?.id
            ?  action
            : item
        ))
       }
    }
    const previous__action_item_triggered_ref = React.useRef({title:""})
    const AiTip =(data)=>{
      let title = data?.value?.trim()
      if(previous__action_item_triggered_ref.current.title !=title && title &&title?.trim()!==""){
        if(SetAITrigger){
          previous__action_item_triggered_ref.current ={title}
          let trigger = {
            trigger:ai_trigger_content[data?.key],
            dynamic_data :[title],
            optype:"VIEW",
            objectId:meetingByIdData?.id
          }
          SetAITrigger({...trigger})
        }
      } 
    }
  return (
    <>
        <div className='meeting-overflow agenda-list-container'style={{width:"100%",maxHeight:"280px",overflow:'auto',marginTop:"20px"}}>
            {!noAddOption&&<div>
                {(addbutton&&!addAgenda)&&<div className="one-on-one-meeting-items-add-btn" onClick={()=>{setAddAgenda(true);actionEnable(true)}}>
                + New ACTION ITEM
                </div>}
                {addAgenda&&
                <Spin spinning={loading}>
                  {/* while changing the content of header prop be causious because few conditions are validated with the content of header */}
                <AddAgendaContainer 
                    {...props}
                    memberlist={memberlist}
                    header={"Add Action Item"}
                    onSubmit={(e)=>{SubmitActionItem(e)}}
                    me={me}
                    close={()=>{setAddAgenda(false);setSelectedItem();actionEnable(false)}}
                    agenda={selectedItem}
                    isAgenda={false}
                    meetingStarted={meetingStarted}
                    AiTip={(key)=>AiTip(key)}
                />
                </Spin>
                }
            </div>}
             {!addAgenda&&
                <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => <CaretRightOutlined className="collapse-right-outlined-icon" rotate={isActive ? 90 : 0} />}
                    style={{ background: '#FFF' }}
                    >
                        {actionListvalue?.length?actionListvalue?.map((item,index)=>(
                            <Panel  className="agend-list-div" style={{marginTop:"10px",marginBottom: 14,border:'none',borderRadius:"8px",padding:"0px 0px"}} header={<div style={{display:"flex",flexDirection:"row",gap:"20px",justifyContent:"space-between",alignItems:"center"}} onClick={(e)=>e.stopPropagation()}>
                                    <Row align="middle" style={{gap:"10px",width:"100%"}}>
                                      <h4 className="one-on-one-agenda-title" style={{textAlign:"left"}}>{item?.title}</h4>
                                      {statusChange&&<a style={{fontFamily:"Poppins",fontWeight:"500"}} onClick={()=>ChangeStatus({status:!item?.completed,id:item?.id})}>{item?.completed?"Mark as incompleted":"Mark as completed"}</a>}
                                    </Row>
                                    
                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",flexWrap:"nowrap",gap:"10px"}}>
                                        {/* <img src={bookmark_disabel} alt="" style={{cursor:"pointer"}} /> */}
                                        {!noMenu && getIntFromString(me?.id) === getIntFromString(meetingByIdData?.owner?.user?.id) &&<Dropdown overlay={
                                            <Menu style={{borderRadius:" 6.5px",boxShadow:" -0.5px 3px 13.5px -4.5px rgba(0, 0, 0, 0.31)",width:"80px",display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
                                            <Menu.Item key="edit" style={{display:"flex",flexDirection:"row",justifyContent:'left',alignItems:"center",gap:"5px",fontFamily:"Poppins",fontWeight: 500,fontSize:"12px",cursor:"pointer",width:"100%"}}  onClick={()=>EditActionItem(item)}><img src={edit_icon} alt="" /> Edit</Menu.Item>
                                            <Menu.Item key="delete" style={{display:"flex",flexDirection:"row",justifyContent:'left',alignItems:"center",gap:"5px",fontFamily:"Poppins",fontWeight: 500,fontSize:"12px",cursor:"pointer",width:"100%"}} onClick={()=>RemoveActionItem(item)}><img src={delete_icon} alt="" />Delete</Menu.Item>
                                            </Menu>}placement="bottomCenter"><img src={three_dot} alt="" style={{cursor:"pointer"}}/>
                                        </Dropdown>}
                                    </div>
                                </div>} 
                            key={index+1}>
                                {text(item)}
                            </Panel>
                        )): <div style={{ justifyContent: "center", alignItems: "center", display: "flex", width: "100%", paddingTop: "40px" }}>

                        {!hidePlaceHolder ? <h4 className='no-data' style={{color:'#BFBFBF'}}>No Action Item Found</h4> : <div></div>}
                      </div>}  
                </Collapse>   
            }
        </div>
        <Modal 
         footer={null}
         centered
         visible={deleteModal}
         destroyOnClose={true}
         style={{height:"300px"}}
         closable={false}>
            <div style={{position:"relative",width:"100%"}} >
                <h2 className="one-on-one-meeting-popup-head" style={{textAlign:"center"}}>Warning</h2>
                <img src={Agenda_close} alt=""style={{position:"absolute",right:"0px",top:"0px",cursor:"pointer"}}onClick={()=>{setDeleteModal(false);setSelectedItem()}} />
            </div>
            <OneonOneDeleteModal title={'Are you sure you want to delete the action item?'} selectedData={selectedActionItem} onCancel={()=>{setDeleteModal(false);setHideButtonPanel(false)}} deleteSeletedFeedback={e=>deleteSelected(e)} type={'actionItem'}/>
        </Modal>
    </>
)
}

export default ActionListView