import gql from "graphql-tag";

export const MEETING_BY_ID = gql`
query meetingById($id:ID!){
    meetingById(id:$id){
        id
        title
        meetingType
        scheduleDuration
        rrule
        summary
        isInstant
        nextMeeting{
          totalCount
        }
        seriesId
        feedback {
          edges {
            node {
              detailFillIn
              id
              updatedAt
              feedbackTo {
                id
                user {
                  id
                  firstName
                  lastName
                }
              }
              response{
                id
                answerSet{
                  edges{
                    node{
                      id
                      answer
                      question{
                        id
                        questionAddress
                        questionText
                      }
                    }
                  }
                }
              }
              feedbackType
            }
          }
        }
        member {
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                email
                profile {
                  id
                  profileImage
                }
                employee{
                  id
                }
              }
            }
          }
        }
        owner {
          id
          user {
            id
            firstName
            lastName
            employee{
              id
            }
            profile {
              id
              profileImage
            }
          }
        }
        when
        createdAt
        updatedAt
        meetingnotesSet {
          edges {
            node {
              id
              notes
              employee{
                id
                user{
                  id
                  username
                }
              }
            }
          }
        }
        previousMeeting{
          id
          title
        }
        nextMeeting{
          edges{
            node{
              id
              title
            }
          }
        }
        actionitemSet {
          edges {
            node {
              id
              title
              notes
              updatedAt
              completed
              meeting {
                id
                title
              }
              okr {
                id
                title
                okrType
                progress
                owner {
                  id
                  user {
                    id
                    firstName
                    lastName
                    employee {
                      id
                    }
                  }
                }
              }
              
              commitment {
                id
                commitment {
                  id
                  title
                }
                user{
                  id
                  firstName
                  lastName
                  employee{
                    id
                  }
                }
                completed
              }
              coachingCommitment {
                id
                coachingcommitment {
                  id
                  title
                }
                user{
                  id
                  firstName
                  lastName
                  employee{
                    id
                  }
                }
                completed
              }
              notes
              completed
              isAgenda
              createdAt
              updatedAt
            }
          }
        }
      }
}
`;


export const MEETING_BY_ID_OPTIMIZED = gql`
query meetingById($id:ID!){
    meetingById(id:$id){
        id
        endedAt
        title
        meetingType
        scheduleDuration
        rrule
        summary
        isInstant
        nextMeeting{
          totalCount
        }
        seriesId
        member {
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                email
                profile {
                  id
                  profileImage
                }
                employee{
                  id
                }
              }
            }
          }
        }
        owner {
          id
          user {
            id
            firstName
            lastName
            employee{
              id
            }
            profile {
              id
              profileImage
            }
          }
        }
        when
        createdAt
        updatedAt
        meetingnotesSet {
          edges {
            node {
              id
              notes
              employee{
                id
                user{
                  id
                  username
                }
              }
            }
          }
        }
        previousMeeting{
          id
          title
          actionitemSet{
            edges{
              node{
                id
                title
              }
            }
          }
          action_item: actionitemSet(isAgenda:false){
            edges{
              node{
                id
                title
              }
            }
          }
        }
        nextMeeting{
          edges{
            node{
              id
              title
            }
          }
        }
        agenda_count:actionitemSet(isAgenda:true){
          totalCount
          edges{
          node{
            id
            title 
          }
          }
        }
      }
}
`;

export const MEETING_BY_ID_SUBSCTIPTION =gql`
subscription meetingSubscription {
  meetingSubscription{
      mutation
      meeting{
        id
        title
        meetingType
        scheduleDuration
        rrule
        summary
        isInstant
        nextMeeting{
          totalCount
        }
        seriesId
        feedback {
          edges {
            node {
              detailFillIn
              id
              updatedAt
              feedbackTo {
                id
                user {
                  id
                  firstName
                  lastName
                }
              }
              response{
                id
                answerSet{
                  edges{
                    node{
                      id
                      answer
                      question{
                        id
                        questionAddress
                        questionText
                      }
                    }
                  }
                }
              }
              feedbackType
            }
          }
        }
        member {
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                email
                profile {
                  id
                  profileImage
                }
                employee{
                  id
                }
              }
            }
          }
        }
        owner {
          id
          user {
            id
            firstName
            lastName
            employee{
              id
            }
            profile {
              id
              profileImage
            }
          }
        }
        when
        createdAt
        updatedAt
        meetingnotesSet {
          edges {
            node {
              id
              notes
              employee{
                id
                user{
                  id
                  username
                }
              }
            }
          }
        }
        previousMeeting{
          id
          title
        }
        nextMeeting{
          edges{
            node{
              id
              title
            }
          }
        }
        actionitemSet {
          edges {
            node {
              id
              title
              notes
              updatedAt
              completed
              meeting {
                id
                title
              }
              okr {
                id
                title
                okrType
                progress
                owner {
                  id
                  user {
                    id
                    firstName
                    lastName
                    employee {
                      id
                    }
                  }
                }
              }
              
              commitment {
                id
                commitment {
                  id
                  title
                }
                user{
                  id
                  firstName
                  lastName
                  employee{
                    id
                  }
                }
                completed
              }
              coachingCommitment {
                id
                coachingcommitment {
                  id
                  title
                }
                user{
                  id
                  firstName
                  lastName
                  employee{
                    id
                  }
                }
                completed
              }
              notes
              completed
              isAgenda
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`