import React from "react";
import TeamIdpView from "../components/teamIdpView";
import { globalPermissionValidator } from "modules/look";
import { idp_permission } from "Permissions/idp.permission";
import IdpAiTipContainer from "modules/ai-tips/container/idp-ai-tip-container";

const TeamIdpContainer = (props) => {
    const { userPermission } = props
    const [permission, setPermission] = React.useState()
    const [ai_trigger, SetAITrigger] = React.useState()

    //Permission Check
    React.useEffect(() => {

        if (userPermission?.length) {
            let permission = globalPermissionValidator(idp_permission, userPermission)
            setPermission(permission)
        }
    }, [userPermission])


    return (
        <>
            {
                (permission && permission?.runpod_permission) && (
                    <IdpAiTipContainer 
                    {...props} 
                    ai_trigger={ai_trigger}
                    SetAITrigger={SetAITrigger}/>
                )
            }
            <TeamIdpView
                {...props}
                ai_trigger={ai_trigger}
                SetAITrigger={SetAITrigger}
            />
        </>
    )
}

export default TeamIdpContainer