import { compose } from "modules/core"
import { withApollo } from '@apollo/client/react/hoc';
import React from "react";
import AITipsView from "../component/AI-tips-view";
import { AI_TIP_TRIGGER, AI_TIPS, TIP_SETTINGS } from "../query/ai-tip-query.gql";
import { AI_TIP_SUBSCRIPTION } from "../query/ai-tip-subscription.gql";
import { GenerateTipTitle, getIntFromString, replaceAITipDynamicContent, setTriggerToLS } from "modules/look";
import { basic_ai_prompt } from "../ai-trigger-content";

const IDPAIContainer = (props) => {
    const { ai_trigger, me, client, SetCancelAiTriggerTip } = props
    const [preview_tip, SetPreviewTip] = React.useState()
    const [tip_list, SetList] = React.useState([])
    const [cancel_trigger, SetCancelTrigger] = React.useState([])
    let tip_list_ref = React.useRef([])
    const ai_tip_subscription_ref = React.useRef()
    let cancel_tip_ref = React.useRef([])

    React.useEffect(() => {
        cancel_tip_ref.current = cancel_trigger
        setTriggerToLS(cancel_tip_ref.current)
    }, [cancel_trigger])

    React.useEffect(() => {
        return () => {
            if (ai_tip_subscription_ref?.current) {
                ai_tip_subscription_ref.current.unsubscribe();
            }
            if (cancel_tip_ref.current?.length && SetCancelAiTriggerTip) {
                SetCancelAiTriggerTip([]?.concat(cancel_tip_ref.current))
            }
        }
    }, [])

    React.useEffect(() => {
        tip_list_ref.current = tip_list
    }, [tip_list])

    React.useEffect(() => {
        if (ai_trigger) {
            generateTip(ai_trigger)
        }
    }, [ai_trigger])

    React.useEffect(() => {
        if (me) {
            GetTipList()
            if (!ai_tip_subscription_ref?.current) {
                aiTipSubscription()
            }
        }
    }, [me])

    const GetTipList = async () => {
        try {
            const { data } = await client.query({
                query: AI_TIPS,
                variables: { first: 15, user: me?.id, tipModule: "IDP" },
                fetchPolicy: 'network-only'
            });
            if (data?.allRunpodtips) {
                let list = handleAITipdata(data?.allRunpodtips?.edges)
                SetList(list)
            }

        } catch (error) {

        }
    }

    const generateTip = async (triger_data) => {
        try {
            if (cancel_tip_ref.current?.length && SetCancelAiTriggerTip) {
                SetCancelAiTriggerTip([]?.concat(cancel_tip_ref.current))
            }
            let is_ai_tip_enabled = await getAiTipSetting()
            if (!is_ai_tip_enabled) {
                return
            } 
            let { trigger, dynamic_data, userType, optype, custom_instruction, objectId,tipFor } = triger_data
            const randomInt = Math.floor(Math.random() * trigger?.length);
            let instruction_detail = trigger[randomInt] || trigger[0]
            if (!instruction_detail) {
                return
            }
          
            if (instruction_detail?.dynamic_data >= 1 && dynamic_data?.length) {
                instruction_detail = replaceAITipDynamicContent(instruction_detail, dynamic_data)
            }
          
            
            startAILoader()
            const { data } = await client.query({
                query: AI_TIP_TRIGGER,
                variables: { 
                    instruction: custom_instruction ?basic_ai_prompt + custom_instruction : "",
                    situation :instruction_detail?.title,
                    aiContext: optype != "VIEW" ? JSON.stringify({action:instruction_detail.ai_context}):null, 
                    userType: userType || "employee", 
                    module: "IDP",
                    optype:optype || "CREATE",
                    objectId,
                    tipFor:tipFor||null
                },
                fetchPolicy: 'network-only'
            });

        } catch (error) {
            stopAILoader()
        }
    }

    const aiTipSubscription = () => {

        ai_tip_subscription_ref.current = client.subscribe({
            query: AI_TIP_SUBSCRIPTION,
        }).subscribe({
            next(result) {
                let data_from_sub = result.data.tipSubscription?.tip
                if (getIntFromString(data_from_sub?.user?.id) == getIntFromString(me?.id) && data_from_sub?.tipModule == "IDP") {
                    switch (result.data.tipSubscription.mutation) {
                        case 'UPDATE':
                            let already_added = tip_list_ref.current?.find(i => getIntFromString(i?.id) == getIntFromString(data_from_sub?.id))
                            if (data_from_sub?.tip && !already_added) {
                                stopAILoader()
                                SetCancelTrigger(cancel_tip_ref.current?.filter(i=>i?.jobId!=data_from_sub?.runpodId))
                                let list = tip_list_ref.current
                                let tip_data = handleAITipdata([{ node: data_from_sub }])
                                SetList(tip_data?.concat(list))
                                SetPreviewTip(tip_data[0]) 
                            }
                            break

                        case "CREATE":
                            SetCancelTrigger(cancel_tip_ref.current?.concat({
                                jobId: data_from_sub?.runpodId
                            }))
                            break
                        default:
                            break
                    }
                }
            }
        })

    }

    const handleAITipdata = (list) => {
        return list?.map(({ node }) => {
            return {
                id: node?.id,
                tipFor: node?.tipFor,
                user: node?.user,
                module: node?.tipModule,
                title: GenerateTipTitle(node),
                is_saved: node?.favourite,
                content: node?.tip,
                created_at: node?.createdAt
            }
        })
    }
    const getAiTipSetting = async () => {
        try {
            const { data } = await client.query({
                query: TIP_SETTINGS,
                variables: { module: "IDP" },
                fetchPolicy: 'network-only'
            });
            if (data) {
                if (data?.tipSettingsByUser?.edges?.length) {
                    return data?.tipSettingsByUser?.edges[0]?.node?.active
                }
                else {
                    return true
                }
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    }

    let loader_timer = React.useRef()

    const startAILoader = () => {
        if (loader_timer?.current) {
            clearTimeout(loader_timer?.current)
        }
        SetPreviewTip({
            id: "loader",
            loader: true
        })
        loader_timer.current = setTimeout(() => {

            SetPreviewTip(null)

        }, 50000);
    }

    const stopAILoader = () => {
        if (loader_timer?.current) {
            clearTimeout(loader_timer?.current)
        }
    }

    return (
        <AITipsView module={"IDP"} {...props} preview_tip={preview_tip} SetPreviewTip={SetPreviewTip} tip_list={tip_list} stopAILoader={stopAILoader} />
    )
}

export default compose(withApollo)(IDPAIContainer)
